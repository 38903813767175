import React, { useState } from 'react';

import styles from './HomeWindow.module.css';
import stylesCommon from './CommonWindow.module.css';
import { itemDictionary, defaultSettings } from '../data';
import ComputerComponent from '../components/decorative/ComputerComponent';

function HomeWindow(args) {
    const [formData, setFormData] = useState({
        colorMode: args.settings.colorMode !== undefined ? args.settings.colorMode : defaultSettings.colorMode,
        useIcons: args.settings.useIcons !== undefined ? args.settings.useIcons : defaultSettings.useIcons,
        animations: args.settings.animations !== undefined ? args.settings.animations : defaultSettings.animations,
        sheetPrecision: args.settings.sheetPrecision !== undefined ? args.settings.sheetPrecision : defaultSettings.sheetPrecision,
        useMinimap: args.settings.useMinimap !== undefined ? args.settings.useMinimap : defaultSettings.useMinimap,
        target: args.settings.target || ''
    });

    const resetSettings = () => {
        setFormData(defaultSettings);
        localStorage.setItem('settings', JSON.stringify(defaultSettings));
        args.setSettings(defaultSettings);
    }
    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: type === 'checkbox' ? checked : value // Use 'checked' for checkboxes
        };
        setFormData(updatedFormData);
        localStorage.setItem('settings', JSON.stringify(updatedFormData));
        args.setSettings(updatedFormData);
    };


    return (
        <div className={`${styles.HomeWindow} ${stylesCommon.window_basic}`}>

            <div className={stylesCommon.window_titlebar}>

                <span>hudOS</span>
                <span className={stylesCommon.closewindowbutton} onClick={() => args.closeWindow()}>x</span>
            </div>

            <form>
                <ComputerComponent animations={args.settings.animations} />

                <div className={styles.settings}>
                    <section>
                        <h2>Display</h2>
                        <label>Use icons for items <select name='useIcons' onChange={handleInputChange} value={formData.useIcons}>
                            <option value='text'>Text</option>
                            <option value='icon'>Icon</option>
                            <option value='both'>Text + Icon</option>
                        </select></label>
                        <label>Color Mode <select name='colorMode' onChange={handleInputChange} value={formData.colorMode}>
                            <option value='dark'>Dark</option>
                            <option value='light'>Light</option>
                        </select></label>
                        <label>Animations
                            <input type='checkbox' name='animations' onChange={handleInputChange} checked={formData.animations} />
                        </label>
                        <label>Minimap (Factory)
                            <input type='checkbox' name='useMinimap' onChange={handleInputChange} checked={formData.useMinimap} />
                        </label>
                    </section>


                    <section>
                        <h2>Data</h2>
                        <label>Target<select name='target' onChange={handleInputChange} value={formData.target}>
                            <option value={null}>
                                None
                            </option>
                            {itemDictionary.map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select></label>

                        <label>Sheet Precision
                            <input type='number' name='sheetPrecision' onChange={handleInputChange} value={formData.sheetPrecision} />
                            &nbsp; Eg. {3.333333333333.toFixed(formData.sheetPrecision)}
                        </label>
                    </section>

                    <section>
                        <h2>Reset</h2>
                        <label>Settings <span className={styles.settingsButton} onClick={resetSettings}>Reset to default</span></label>
                    </section>
                </div>


            </form>
        </div>
    );
}

export default HomeWindow;

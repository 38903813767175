



import React from 'react';
import styles from './ComputerComponent.module.css';
import osIcon from '../../img/checkit.webp';
import { appVersion } from '../../data';

const ComputerComponent = ({ animations }) => {
    return (
        <div className={styles.ComputerComponent}>
            <div className={styles.computer}>
                <div className={`${styles.computerDecorationBottom} ${animations === true
                    ? styles.computerDecorationBottomAnimated
                    : ''
                    }`}></div>
                <div className={styles.computerDecorationTop}>
                    FICSIT, Inc.
                </div>
                <div className={`${styles.computerScreen} ${animations === true ? styles.computerScreenAnimated : ''}`}>
                    <img src={osIcon} alt='hudOS' className={styles.osIcon} /> <span className={styles.osName}>hudOs {appVersion}</span>
                </div>

            </div>
            <div className={styles.computerStand}>
            </div>
        </div>
    );
};

export default ComputerComponent;

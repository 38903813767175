import styles from './CodexWindowHelpers.module.css';
import React from 'react';

export const codexData = [
    { id: 'factory', name: 'Factory' },
    { id: 'recipes', name: 'Recipes' },
    { id: 'sheet', name: 'Sheet' },
    { id: 'settings', name: 'Settings & Data' },
    { id: 'calculator', name: 'Calculator' },
];

export const getEntryData = (entryId) => {
    switch (entryId) {
        case 'factory':
            return (
                <div className={styles.codexEntry}>
                    <h4>Adding a building</h4>
                    <p>Left click the building icon from the menu to add it to the factory.<br />
                        The "Other Factory" building emulates conveyor belts from other sources.
                        It is excluded from power consumption calculations and cannot have somersloops.
                        This building should not be overclocked : its recipe should represent the net quantity of parts received from belts.
                    </p>

                    <h4>Setting up a building</h4>
                    <p>Right click a building to open its settings. You can select its recipe here<br />
                        This will not check: <br />
                        <ul>
                            <li>Whether this recipe exists in-game</li>
                            <li>if the items are of the correct type (parts / fluids)</li>
                            <li>if the recipe is usable with that building in-game</li>
                            <li>if the output exceeds the maximum possible output (Mk.6 Conveyor belts: 1200 ppm / Pipelines Mk.2: 600m3/min</li>
                        </ul>
                        Note: deleting a recipe will reset the recipes of buildings that used it.</p>

                    <h4>Deleting a building</h4>
                    <p>
                        Double click a building to delete it.
                    </p>

                    <h4>Cloning a building</h4>
                    <p>
                        Click the (+) icon on a building to clone it.
                    </p>

                    <h4>Managing connections</h4>
                    <p>
                        Click and hold on an handle and drop it on an handle of opposite type to connect their buildings.<br />
                        Left click on a connection to delete it.<br />
                        Buildings can have an unlimited number of connections.
                    </p>

                </div>
            );
        case 'recipes':
            return (
                <div className={styles.codexEntry}>
                    All recipes should use the base per minute value (non-overlocked, no somersloop).<br />
                    A recipe may have no input or no output.
                </div>
            );
        case 'sheet':
            return (
                <div className={styles.codexEntry}>
                    This is a global view of your production.<br />
                    Missing parts are indicated in the totals. If there are missing items, some buildings will have downtimes and production will decrease.<br />
                    Products are also shown. They need to be managed or the factory will stop!<br />
                    The total power consumption does not include any power generation within the factory.<br />
                    If a target is selected in Settings &gt; Data, it will be highlighted here.
                </div>
            );
        case 'calculator':
            return (
                <div>
                    <table className={styles.codexTable}>
                        <thead>
                            <tr>
                                <td>Key</td>
                                <td>⇒</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>0-9</td>
                                <td>⇒</td>
                                <td>Numbers input</td>
                            </tr>
                            <tr>
                                <td>+, -, *, /</td>
                                <td>⇒</td>
                                <td>Operations</td>
                            </tr>
                            <tr>
                                <td>DELETE</td>
                                <td>⇒</td>
                                <td>AC (Reset)</td>
                            </tr>
                            <tr>
                                <td>←</td>
                                <td>⇒</td>
                                <td>Undo</td>
                            </tr>
                            <tr>
                                <td>CTRL + C</td>
                                <td>⇒</td>
                                <td>Copy</td>
                            </tr>
                            <tr>
                                <td>CTRL + V</td>
                                <td>⇒</td>
                                <td>Paste</td>
                            </tr>
                            <tr>
                                <td>Enter, =</td>
                                <td>⇒</td>
                                <td>=</td>
                            </tr>
                            <tr>
                                <td>F1</td>
                                <td>⇒</td>
                                <td>Show this</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        case 'settings':
            return (
                <div>
                    All app data (including settings) is stored in your browser local storage. Export your factories to keep a permanent version and to use them in a another browser.
                </div>
            );

        default:
            return (<div>Entry not found</div>);
    }
};

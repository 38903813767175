import React, { useState, useMemo } from 'react';
import stylesCodex from './CodexWindow.module.css';
import stylesCommon from './CommonWindow.module.css';

import { codexData, getEntryData } from './HelpWindowHelpers';

function HelpWindow(args) {




    // Store refs dynamically for all sections
    const sectionRefs = useMemo(() => {
        return codexData.reduce((refs, item) => {
            refs[item.id] = React.createRef();
            return refs;
        }, {});
    }, []);

    const [activeSection, setActiveSection] = useState('');

    const scrollToSection = (sectionKey) => {
        const section = sectionRefs[sectionKey]?.current;
        const codexContent = document.querySelector(`.${stylesCodex.codexContent}`);

        if (section && codexContent) {
            const sectionTop = section.offsetTop;
            const offset = window.innerHeight * 0.05; // 5vh in pixels
            codexContent.scrollTo({
                top: sectionTop - offset,
                behavior: 'smooth',
            });
            setActiveSection(sectionKey);
        }
    };

    return (
        <div className={`${stylesCodex.CodexWindow} ${stylesCommon.window_basic}`}>
            <div className={stylesCommon.window_titlebar}>
                <span>Help</span>
                <span className={stylesCommon.closewindowbutton} onClick={() => args.closeWindow()}>x</span>
            </div>

            <div className={stylesCodex.codexMain}>
                <div className={stylesCodex.codexNav}>
                    {codexData.map((item, index) => (
                        <span
                            key={index}
                            onClick={() => scrollToSection(item.id)}
                            className={`${stylesCodex.codexNavLink} ${activeSection === item.id ? stylesCodex.activeNavLink : ''}`}
                        >
                            {item.name}
                        </span>
                    ))}
                </div>

                <div className={stylesCodex.codexContent}>
                    {codexData.map((entry, index) => (
                        <section
                            key={index}
                            ref={sectionRefs[entry.id]}
                            className={`${stylesCodex.section} ${activeSection === entry.id ? stylesCodex.activeSection : ''}`}
                        >
                            <h2>{entry.name}</h2>
                            {getEntryData(entry.id)}
                        </section>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default HelpWindow;

import React from 'react';
import styles from './ContextMenu.module.css';
import ficsitIcon from '../img/ficsitsmall.webp';
import checkitIcon from '../img/checkit.webp';
import { defaultSettings } from '../data';
import { exampleEdges, exampleNodes, exampleRecipesList } from '../dataExampleAluminum';

const ContextMenu = ({ x, y, closeMenu, currentFocus, setCurrentFocus, isAdmin, setIsAdmin, exportFactory }) => {
    const tempFunction = (optionName) => {
        console.log('clicked: ' + optionName);
    };
    const printData = () => {
        const recipes = localStorage.getItem('recipesList');
        const nodes = localStorage.getItem('nodes');
        const edges = localStorage.getItem('edges');
        const settings = localStorage.getItem('settings');
        const settingsClipboard = localStorage.getItem('settingsClipboard');
        const separator = '============--------============';
        console.log('Recipes:');
        console.log(recipes);
        console.log(separator);
        console.log('Nodes:');
        console.log(nodes);
        console.log(separator);
        console.log('Edges:');
        console.log(edges);
        console.log(separator);
        console.log('Settings:');
        console.log(settings);
        console.log(separator);
        console.log('Building Settings Clipboard:');
        console.log(settingsClipboard);
    }
    const cleanAllData = () => {
        localStorage.setItem('recipesList', null);
        localStorage.setItem('nodes', null);
        localStorage.setItem('edges', null);
        localStorage.setItem('settings', defaultSettings);
        localStorage.setItem('settingsClipboard', null);
    }
    const importExampleData = () => {
        localStorage.setItem('recipesList', exampleRecipesList);
        localStorage.setItem('nodes', exampleNodes);
        localStorage.setItem('edges', exampleEdges);
    }
    const handleOptionClick = (option) => {
        if (option.confirmation) {
            if (window.confirm('Are you sure?')) {
                option.action && option.action();
            }
        } else {
            option.action && option.action();
        }
        closeMenu();
    };

    const baseOptions = [
        {
            label: '⚙️ Settings',
            action: () => setCurrentFocus('home'),
        },
        {
            label: '🗺️ Factory Map',
            action: () => setCurrentFocus('factory'),
        },
        {
            label: '📋 Sheet',
            action: () => setCurrentFocus('sheet'),
        },
        {
            label: '📝 Recipes',
            action: () => setCurrentFocus('recipes'),
        },
        {
            label: '💾 Export',
            action: () => exportFactory(),
        },
    ];

    const adminOptions = [
        {
            label: '🔓 Disable Admin Mode',
            action: () => setIsAdmin(false),
        },
        {
            label: '🚨 Clean ALL data',
            action: () => cleanAllData(),
            confirmation: true,
        },
        {
            label: '🖨️ Print data',
            action: () => printData()
        },
        {
            label: '🗃️ Import example data',
            action: () => importExampleData(),
            confirmation: true
        }
    ];

    const notAdminOption = {
        label: '🔐 Enable Admin Mode',
        action: () => setIsAdmin(true),
    };

    const recipesOption = {
        label: '➕ Add a recipe',
        action: () => tempFunction('add recipe'),
    };

    const sheetOption = {
        label: '🖨️ Print sheet',
        action: () => tempFunction('print'),
    };

    return (
        <div
            className={styles.contextMenu}
            onMouseLeave={closeMenu} style={{ top: y, left: x }}
        >
            <div className={styles.contextMenuHeader}>
                <img className={styles.contextMenuHeaderCheckit} src={checkitIcon} alt="" />
                <img className={styles.contextMenuHeaderFicsit} src={ficsitIcon} alt="" />
            </div>

            {currentFocus === 'recipes' && (
                <div
                    className={styles.menuItem}
                    onClick={() => handleOptionClick(recipesOption)}
                >
                    {recipesOption.label}
                </div>
            )}
            {currentFocus === 'sheet' && (
                <div
                    className={styles.menuItem}
                    onClick={() => handleOptionClick(sheetOption)}
                >
                    {sheetOption.label}
                </div>
            )}

            {baseOptions.map((option, index) => (
                <div
                    key={index}
                    className={styles.menuItem}
                    onClick={() => handleOptionClick(option)}
                >
                    {option.label}
                </div>
            ))}

            {!isAdmin && (
                <div
                    className={styles.menuItem}
                    onClick={() => handleOptionClick(notAdminOption)}
                >
                    {notAdminOption.label}
                </div>
            )}

            {isAdmin &&
                adminOptions.map((option, index) => (
                    <div
                        key={index}
                        className={styles.menuItem}
                        onClick={() => handleOptionClick(option)}
                    >
                        {option.label}
                    </div>
                ))}

            <div className={styles.contextMenuFooter}>•</div>
        </div>
    );
};

export default ContextMenu;



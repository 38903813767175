import React, { useState, useEffect } from 'react';
import RecipesWindow from './windows/RecipesWindow';
import RecipeAddWindow from './windows/RecipeAddWindow';
import SheetWindow from './windows/SheetWindow';
import menuIcon from './img/checkit.webp';
import { defaultSettings } from './data';
import styles from './App.module.css';
import FactoryWindow from './windows/FactoryWindow';
import HomeWindow from './windows/HomeWindow';
import CodexWindow from './windows/CodexWindow';
import ContextMenu from './components/ContextMenu';
import CalculatorWindow from './windows/CalculatorWindow';
import LoginWindow from './windows/LoginWindow';
import HelpWindow from './windows/HelpWindow';
function App() {
  const [currentFocus, setCurrentFocus] = useState('recipes');
  const [recipesList, setRecipesList] = useState([]);
  const [settings, setSettings] = useState(defaultSettings);
  const [contextMenu, setContextMenu] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);

  const handleRightClick = (event) => {
    event.preventDefault();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
    });
  };
  const removeAccess = () => {
    localStorage.setItem('access', false);
    setHasAccess(false);
  }
  useEffect(() => {
    const recipeData = localStorage.getItem('recipesList');
    const recipeDataParsed = JSON.parse(recipeData);
    setRecipesList(recipeDataParsed);

    const settingsData = localStorage.getItem('settings');
    if (settingsData) {
      const settingsParsedData = JSON.parse(settingsData);
      setSettings(settingsParsedData);
    }
  }, []);
  useEffect(() => {
    const access = localStorage.getItem('access');
    setHasAccess(access);
  }, []);
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', settings.colorMode);
  }, [settings.colorMode]);

  const exportFactory = () => {
    console.log("exporting factory/ data=");
    const nodes = localStorage.getItem('nodes');
    const edges = localStorage.getItem('edges');
    const data = {
      recipesList,
      nodes,
      edges
    }
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'factory.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  const importFactory = (event) => {
    console.log("importing factory");
    const file = event.target.files[0];
    if (file && file.type === 'application/json') {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const parsedData = JSON.parse(e.target.result);  // Parse JSON content
          const recipes = parsedData.recipesList;
          const nodes = parsedData.nodes;
          const edges = parsedData.edges;
          setRecipesList(recipes);
          localStorage.setItem('recipesList', null);
          localStorage.setItem('recipesList', JSON.stringify(recipes));
          localStorage.setItem('nodes', null);
          localStorage.setItem('nodes', JSON.stringify(nodes));
          localStorage.setItem('edges', null);
          localStorage.setItem('edges', JSON.stringify(edges));
        } catch (error) {
          console.error('Error parsing JSON:', error);
          alert('Invalid JSON file');
        }
      };
      reader.readAsText(file);  // Read the file as text
    } else {
      alert('Please upload a valid JSON file');
    }
  };

  return (
    <div className={styles.App} onContextMenu={handleRightClick}>
      {hasAccess !== 'true' && (
        <LoginWindow setHasAccess={setHasAccess} settings={settings} />
      )}
      {hasAccess === 'true' && (
        <>
          <div className={styles.menu}>
            <span className={currentFocus === 'home' ? styles.active : ''} onClick={() => setCurrentFocus('home')}>
              <img src={menuIcon} className={styles.menuIcon} alt='hudOS' /> Settings
            </span>
            <input type="file" id='inputImport' accept="application/json" onChange={importFactory} style={{ 'display': 'none' }} />
            <span onClick={() => exportFactory()}>Export</span>
            <span onClick={() => document.getElementById('inputImport').click()}>Import</span>
            <span className={currentFocus === 'recipes' ? styles.active : ''} onClick={() => setCurrentFocus('recipes')}>Recipes</span>
            <span className={currentFocus === 'factory' ? styles.active : ''} onClick={() => setCurrentFocus('factory')}>Factory</span>
            <span className={currentFocus === 'sheet' ? styles.active : ''} onClick={() => setCurrentFocus('sheet')}>Sheet</span>
            <span className={currentFocus === 'codex' ? styles.active : ''} onClick={() => setCurrentFocus('codex')}>Codex</span>
            <span className={currentFocus === 'calculator' ? styles.active : ''} onClick={() => setCurrentFocus('calculator')}>Calculator</span>
            <span className={currentFocus === 'help' ? styles.active : ''} onClick={() => setCurrentFocus('help')}>Help</span>
            <span className={currentFocus === 'login' ? styles.active : ''} onClick={() => removeAccess()}>Logoff</span>
          </div>

          {currentFocus === 'recipes' && (
            <RecipesWindow setCurrentFocus={setCurrentFocus} settings={settings} recipesList={recipesList} setRecipesList={setRecipesList} />
          )}
          {currentFocus === 'recipes_add' && (
            <RecipeAddWindow closeWindow={() => setCurrentFocus('recipes')} settings={settings} recipesList={recipesList} setRecipesList={setRecipesList} />
          )}
          {currentFocus === 'factory' && (
            <FactoryWindow settings={settings} recipesList={recipesList} />
          )}
          {currentFocus === 'sheet' && (
            <SheetWindow closeWindow={() => setCurrentFocus('recipes')} recipesList={recipesList} settings={settings} />
          )}
          {currentFocus === 'home' && (
            <HomeWindow closeWindow={() => setCurrentFocus('recipes')} settings={settings} setSettings={setSettings} />
          )}
          {currentFocus === 'codex' && (
            <CodexWindow closeWindow={() => setCurrentFocus('recipes')} settings={settings} />
          )}
          {currentFocus === 'calculator' && (
            <CalculatorWindow closeWindow={() => setCurrentFocus('recipes')} settings={settings} />
          )}
          {currentFocus === 'help' && (
            <HelpWindow closeWindow={() => setCurrentFocus('recipes')} settings={settings} />
          )}
        </>
      )}

      {contextMenu && currentFocus !== 'factory' && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          closeMenu={() => setContextMenu(null)}
          currentFocus={currentFocus}
          setCurrentFocus={setCurrentFocus}
          isAdmin={isAdmin}
          setIsAdmin={setIsAdmin}
          exportFactory={exportFactory}
        />
      )}
    </div>
  );
}

export default App;

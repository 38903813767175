export const numberButtons = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
export const numberShortcutButtonsBasic = [300, 600]; //shortcuts for commonly used numbers
export const numberShortcutButtonsSpeed = [120, 240, 480]; //shortcuts for commonly used numbers
export const customMultiplyShortcuts = ['x2', 'x3', 'x6', 'x9']; //shorcuts for commonly used operations (*)
export const customDivideShortcuts = ['½', '⅓', '⅙', '⅑']; //shorcuts for commonly used operations (/)
export const divisors = [2, 3, 4, 6, 9]; //for previewing commonly used divisions. 
export const basicOpButtons = ['/', 'x', '+', '-'];
export const divisionMap = {
    '½': 2,
    '⅓': 3,
    '⅙': 6,
    '⅑': 9,
    '⅕': 5,
    '⅒': 10,
};

export const handleButtonClick = (buttonValue, buttonType, stateBundle) => {
    switch (buttonType) {
        case 'number':
            handleNumbers(buttonValue, stateBundle);
            break;
        case 'point':
            stateBundle.setAddPointOnNextNumber(true);
            break;
        case 'customMultiply':
            handleCustomOp(buttonValue, stateBundle);
            break;
        case 'customDivide':
            handleCustomOp(buttonValue, stateBundle);
            break;
        case 'basicOp':
            handleOperators(buttonValue, stateBundle);
            return;
        case 'equals':
            handleOperators(null, stateBundle);
            return;
        default:
            break;
    }
}
const handleNumbers = (value, stateBundle) => {
    const {
        addPointOnNextNumber,
        currentOp,
        digitBuffer,
        currentValue,
        currentDisplay,
        setDigitBuffer,
        setCurrentValue,
        setCurrentDisplay,
        setAddPointOnNextNumber,
        history,
        setHistory,
        maxPrecision
    } = stateBundle;

    // Save the current state before making changes
    setHistory([
        ...history,
        {
            value: currentValue,
            operator: currentOp,
            display: currentDisplay,
        },
    ]);

    if (addPointOnNextNumber) {
        // Handle decimal point logic
        if (currentOp) {
            if (!digitBuffer.toString().includes('.')) {
                const newDigitBuffer = `${digitBuffer}.${value}`;
                const newDisplay = performCalculations(currentValue, newDigitBuffer, currentOp, maxPrecision);
                setDigitBuffer(newDigitBuffer);
                setCurrentValue(Number(newDisplay));
                setCurrentDisplay(newDisplay);
            } else {
                console.warn('Ignoring input: digit buffer already contains a decimal point.');
            }
        } else {
            if (!currentDisplay.toString().includes('.')) {
                const newDisplay = `${currentDisplay}.${value}`;
                setCurrentDisplay(newDisplay);
                setCurrentValue(Number(newDisplay));
            } else {
                console.warn('Ignoring input: display already contains a decimal point.');
            }
        }
        setAddPointOnNextNumber(false);
        return;
    }
    // Handle normal number input
    if (currentOp) {
        const newBuffer = digitBuffer + value.toString();
        const newDisplay = performCalculations(currentValue, newBuffer, currentOp, maxPrecision);
        setDigitBuffer(newBuffer);
        setCurrentDisplay(newDisplay);
    } else {
        const newValue = Number(currentValue + value.toString());
        setCurrentValue(newValue);
        setCurrentDisplay(newValue);
    }
};


const performCalculations = (a, b, op, precision = 3) => {
    console.log("Performing calculation:" + a + op + b);

    // Helper function to round a number to the desired precision
    const roundToPrecision = (num, precision) => {
        const factor = Math.pow(10, precision);
        return Math.round(num * factor) / factor;
    };

    if (a !== undefined && b !== undefined && op) {
        let result;

        switch (op) {
            case '+':
                result = Number(a) + Number(b);
                break;
            case '-':
                result = Number(a) - Number(b);
                break;
            case 'x':
                result = Number(a) * Number(b);
                break;
            case '/':
                result = Number(a) / Number(b);
                break;
            case '.':
                result = Number(`${a}.${b}`);
                break;
            default:
                console.error("Invalid operator:" + op);
                return NaN;
        }

        // Apply rounding to the result
        return roundToPrecision(result, precision);
    } else {
        console.error("Invalid calculation:" + a + op + b);
        return NaN;
    }
};


const handleOperators = (operator, stateBundle) => {
    const { history, setHistory, currentValue, currentOp, currentDisplay, setCurrentOp, setDigitBuffer, setCurrentValue } = stateBundle;
    if (operator === '*')
        operator = 'x';
    // Save current state to history before proceeding
    setHistory([
        ...history,
        {
            value: currentValue,
            operator: currentOp,
            display: currentDisplay,
        },
    ]);
    // Update state for the new operation
    setCurrentOp(operator);
    setDigitBuffer('');
    setCurrentValue(Number(currentDisplay));
};
/*
const handleCustomOp = (value, stateBundle) => {
    const { currentDisplay, setHistory, history,
        setCurrentValue,
        setCurrentDisplay,
        setAddPointOnNextNumber,
        setCurrentOp,
        setDigitBuffer } = stateBundle;

    // Add the current state to the history
    const newHistoryEntry = {
        value: currentDisplay,
        operator: value,
    };
    setHistory([...history, newHistoryEntry]);

    // Perform the custom operation (already implemented)
    let res = NaN;
    if (value.includes('x')) {
        const multiplier = Number(value.replace('x', ''));
        res = multiplier * Number(currentDisplay);
    } else {
        const divisor = divisionMap[value];
        res = Number(currentDisplay) / divisor;
    }
    setCurrentValue(res);
    setCurrentDisplay(res);
    setAddPointOnNextNumber(false);
    setCurrentOp('');
    setDigitBuffer('');
};
*/
const handleCustomOp = (value, stateBundle) => {
    const {
        currentDisplay,
        setCurrentValue,
        setCurrentDisplay,
        setDigitBuffer,
        setHistory,
        history,
    } = stateBundle;

    // Save the current state to history before applying the operation
    const prevState = { value: currentDisplay };
    setHistory([...history, prevState]);

    let res = NaN;
    if (value.includes('x')) {
        const multiplier = Number(value.replace('x', ''));
        res = multiplier * Number(currentDisplay);
    } else {
        const divisor = divisionMap[value];
        res = Number(currentDisplay) / divisor;
    }

    setCurrentValue(res);
    setCurrentDisplay(res);
    setDigitBuffer('');
};


/*
const apply = (newValue, stateBundle) => {
    stateBundle.setHistory([...stateBundle.history, stateBundle.currentValue]);
    stateBundle.setCurrentValue(newValue);
    stateBundle.setLastInput(newValue);
}


const handleCustomMultiply = (input, stateBundle) => {
    const factor = parseInt(input.slice(1));
    const newValue = stateBundle.currentValue * factor;
    apply(newValue, stateBundle);
}
const handleCustomDivide = (input, stateBundle) => {
    const factor = divisionMap[input];
    const newValue = stateBundle.currentValue / factor;
    apply(newValue, stateBundle);
}

*/





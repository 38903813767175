

import React, { useState, useEffect, useRef } from 'react';
import powerShardIcon from '../img/items/Power_Shard.webp';
import somersloopIcon from '../img/items/Somersloop.webp';
import { takeScreenshot } from './SheetWindowHelpers';
import styles from './SheetWindow.module.css';
import stylesCommon from './CommonWindow.module.css';

import { getIcon } from '../helpers';

import { init } from './SheetWindowHelpers';


function SheetWindow(args) {
    const [data, setData] = useState({
        sheetData: null,
        totals: null
    });
    const divRef = useRef();
    const containerToPrint = document.getElementById('printContainer');
    const hideScrollbars = () => containerToPrint.style.overflow = 'hidden';
    const restoreScrollbars = () => containerToPrint.style.overflow = '';
    useEffect(() => {
        init(args, setData);
    }, [args]);

    if (!data.sheetData || !data.totals) {
        return <div>Loading...</div>
    }

    return (
        <div className={`${styles.SheetWindow} ${stylesCommon.window_basic}`}>
            <div className={stylesCommon.window_titlebar}>
                <span className={stylesCommon.titlebarbutton} onClick={() => takeScreenshot(divRef, hideScrollbars, restoreScrollbars)}>Print</span>

                <span>Factory sheet</span>
                <span className={stylesCommon.closewindowbutton} onClick={() => args.closeWindow()}>x</span>
            </div>
            <div className={styles.buildingsList} id='printContainer' ref={divRef}>
                <div className={styles.factoryTableDecorationLeft}>FICSIT Inc.</div>
                <div className={styles.factoryTableDecorationRight}>FICSIT Inc.</div>
                <table className={styles.factoryTable}>
                    <thead>
                        <tr>
                            <td colSpan={5} className={`${styles.categoryHead} ${styles.factoryHead}`}>
                                {args.settings.target && (
                                    <>
                                        <img src={getIcon(args.settings.target)} className={styles.targetIcon} alt={args.settings.target} />
                                        {args.settings.target}&nbsp;
                                    </>
                                )}
                                Factory
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={5} className={`${styles.categoryHead} ${styles.green1}`}>Production Totals</td>
                        </tr>
                        <tr className={`${styles.categorySubhead} ${styles.green2}`}>
                            <td>In</td>
                            <td>Out</td>
                            <td style={{ width: '40px' }}><img src={powerShardIcon} className={styles.boostIcon} alt='Power Shards' /></td>
                            <td style={{ width: '40px' }}><img src={somersloopIcon} className={styles.boostIcon} alt='Somersloops' /></td>
                            <td style={{ width: '85px' }}>⚡</td>
                        </tr>
                        {data.totals && (
                            <tr className={`${styles.categoryBody} ${styles.green3}`}>
                                <td>
                                    {
                                        data.totals.in.map((item, index) => (
                                            <React.Fragment key={index}>
                                                {args.settings.useIcons === 'text' && (
                                                    <span className={styles.partTotal} >{item.quantity} {item.item}</span>
                                                )}
                                                {args.settings.useIcons === 'icon' && (
                                                    <span className={styles.partTotal} >{item.quantity} <img src={getIcon(item.item)} className={styles.partIcon} alt={item.item} /></span>
                                                )}
                                                {args.settings.useIcons === 'both' && (
                                                    <span className={styles.partTotal}>{item.quantity} {item.item} <img src={getIcon(item.item)} className={styles.partIcon} alt={item.item} /></span>
                                                )}
                                            </React.Fragment>
                                        ))
                                    }
                                </td>
                                <td>
                                    {
                                        data.totals.out.map((item, index) => (
                                            <React.Fragment key={index}>
                                                {args.settings.useIcons === 'text' && (
                                                    <span
                                                        className={`${styles.partTotal} ${item.item === args.settings.target
                                                            ? args.settings.animations
                                                                ? styles.targetPartTotalAnimated
                                                                : styles.targetPartTotal
                                                            : ''
                                                            }`}>
                                                        {item.quantity} {item.item}</span>
                                                )}
                                                {args.settings.useIcons === 'icon' && (
                                                    <span
                                                        className={`${styles.partTotal} ${item.item === args.settings.target
                                                            ? args.settings.animations
                                                                ? styles.targetPartTotalAnimated
                                                                : styles.targetPartTotal
                                                            : ''
                                                            }`}

                                                    >{item.quantity} <img src={getIcon(item.item)} className={styles.partIcon} alt={item.item} /></span>
                                                )}
                                                {args.settings.useIcons === 'both' && (
                                                    <span
                                                        className={`${styles.partTotal} ${item.item === args.settings.target
                                                            ? args.settings.animations
                                                                ? styles.targetPartTotalAnimated
                                                                : styles.targetPartTotal
                                                            : ''
                                                            }`}
                                                    >{item.quantity} {item.item} <img src={getIcon(item.item)} className={styles.partIcon} alt={item.item} /></span>
                                                )}
                                            </React.Fragment>
                                        ))
                                    }
                                </td>
                                <td>{data.totals.powerShards}</td>
                                <td>{data.totals.somersloops}</td>
                                <td>{data.totals.power.toFixed(2)} MW</td>
                            </tr>
                        )}


                        <tr className={`${styles.categorySubhead} ${styles.green2}`}>
                            <td>Missing</td>
                            <td>Products</td>
                            <td colSpan={3} rowSpan={2} className={styles.emptyCell}> </td>

                        </tr>
                        {data.totals && data.totals.missingItems && data.totals.byproducts && (
                            <tr className={`${styles.categoryBody} ${styles.green3}`}>
                                <td>
                                    {
                                        data.totals.byproducts.map((item, index) => (
                                            <React.Fragment key={index}>
                                                {args.settings.useIcons === 'text' && (
                                                    <span className={styles.partTotal}>⚠️ {item.quantity} {item.item}</span>
                                                )}
                                                {args.settings.useIcons === 'icon' && (
                                                    <span className={styles.partTotal}>⚠️ {item.quantity} <img src={getIcon(item.item)} className={styles.partIcon} alt={item.item} /></span>
                                                )}
                                                {args.settings.useIcons === 'both' && (
                                                    <span className={styles.partTotal}>⚠️ {item.quantity} {item.item} <img src={getIcon(item.item)} className={styles.partIcon} alt={item.item} /></span>
                                                )}
                                            </React.Fragment>
                                        ))
                                    }
                                </td>
                                <td>
                                    {data.totals.missingItems.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {args.settings.useIcons === 'text' && (
                                                <span
                                                    className={`${styles.partTotal} ${item.item === args.settings.target
                                                        ? args.settings.animations
                                                            ? styles.targetPartTotalAnimated
                                                            : styles.targetPartTotal
                                                        : ''
                                                        }`}
                                                >
                                                    {item.quantity} {item.item}
                                                </span>
                                            )}
                                            {args.settings.useIcons === 'icon' && (
                                                <span
                                                    className={`${styles.partTotal} ${item.item === args.settings.target
                                                        ? args.settings.animations
                                                            ? styles.targetPartTotalAnimated
                                                            : styles.targetPartTotal
                                                        : ''
                                                        }`}
                                                >
                                                    {item.quantity} <img src={getIcon(item.item)} className={styles.partIcon} alt={item.item} />
                                                </span>
                                            )}
                                            {args.settings.useIcons === 'both' && (
                                                <span
                                                    className={`${styles.partTotal} ${item.item === args.settings.target
                                                        ? args.settings.animations
                                                            ? styles.targetPartTotalAnimated
                                                            : styles.targetPartTotal
                                                        : ''
                                                        }`}
                                                >
                                                    {item.quantity} {item.item} <img src={getIcon(item.item)} className={styles.partIcon} alt={item.item} />
                                                </span>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </td>

                            </tr>
                        )}
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={5} className={`${styles.categoryHead} ${styles.green1}`}>Production</td>
                        </tr>
                        {data.sheetData.map((node, nodeIndex) => (
                            <React.Fragment key={node.id}>
                                <tr key={`${node.id}-title`} className={`${styles.categoryBody} ${styles.green3}`}>
                                    <td colSpan={5} className={`${styles.buildingName} ${styles.green2} ${styles.categorySubhead}`}>
                                        {node.count > 1 && (
                                            <span className={styles.buildingCount}>x{node.count}</span>
                                        )}
                                        {node.name}
                                    </td>
                                </tr>
                                <tr key={`${node.id}-up`} className={`${styles.categoryBody} ${styles.green4}`}>
                                    <td className={`${styles.categoryBody} ${styles.green3} ${styles.recipeInOutLabel}`}>In (ppm)</td>
                                    <td className={`${styles.categoryBody} ${styles.green3} ${styles.recipeInOutLabel}`}>Out (ppm)</td>
                                    <td rowSpan={2}>{node.powerShards}<img src={powerShardIcon} className={styles.boostIcon} alt='Power Shards' /></td>
                                    <td rowSpan={2}>{node.somersloop}<img src={somersloopIcon} className={styles.boostIcon} alt='Somersloops' /></td>
                                    <td rowSpan={2}>{node.power * node.count} MW</td>
                                </tr>
                                <tr key={`${node.id}-down`} className={`${styles.categoryBody} ${styles.green4}`}>
                                    <td>
                                        {node.in && node.in.length > 0 ? (
                                            node.in.map((part, partIndex) => (
                                                <React.Fragment key={`${node.id}-in-${partIndex}`}>
                                                    {args.settings.useIcons === 'text' && (
                                                        <span>
                                                            {part.quantity}
                                                            {part.item}</span>
                                                    )}
                                                    {args.settings.useIcons === 'icon' && (
                                                        <span> {part.quantity} <img src={getIcon(part.item)} className={styles.partIcon} alt={part.item} /> {/* Replace with actual icon source later */}</span>
                                                    )}
                                                    {args.settings.useIcons === 'both' && (
                                                        <span> {part.quantity} {part.item}<img src={getIcon(part.item)} className={styles.partIcon} alt={part.item} /> {/* Replace with actual icon source later */}</span>
                                                    )}
                                                    {partIndex < node.in.length - 1 && <span> + </span>}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <span>/</span>
                                        )}

                                    </td>
                                    <td>
                                        {node.out && node.out.length > 0 ? (
                                            node.out.map((part, partIndex) => (
                                                <React.Fragment key={`${node.id}-out-${partIndex}`}>
                                                    {args.settings.useIcons === 'text' && (
                                                        <span> {part.quantity} {part.item}</span>
                                                    )}
                                                    {args.settings.useIcons === 'icon' && (
                                                        <span> {part.quantity} <img src={getIcon(part.item)} className={styles.partIcon} alt={part.item} /> {/* Replace with actual icon source later */}</span>
                                                    )}
                                                    {args.settings.useIcons === 'both' && (
                                                        <span> {part.quantity} {part.item}<img src={getIcon(part.item)} className={styles.partIcon} alt={part.item} /> {/* Replace with actual icon source later */}</span>
                                                    )}
                                                    {partIndex < node.out.length - 1 && <span> + </span>}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <span>/</span>
                                        )}

                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}



                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SheetWindow;

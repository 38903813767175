
import React, { useState, useEffect } from 'react';
import styles from './RecipeAddWindow.module.css';
import stylesCommon from './CommonWindow.module.css';
import { itemDictionary } from '../data';

const emptyFormData = {
    'part1item': '',
    'part1quantity': '',
    'part2item': '',
    'part2quantity': '',
    'part3item': '',
    'part3quantity': '',
    'part4item': '',
    'part4quantity': '',
    'result1item': '',
    'result1quantity': '',
    'result2item': '',
    'result2quantity': ''
}

function RecipeAddWindow(args) {
    const [list, setList] = useState([]);



    const [formData, setFormData] = useState(emptyFormData);
    // Step 3: Update state when input values change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };

        setFormData(updatedFormData);


    };

    // Step 5: Optional submit handler (you can handle form submission here)
    const handleSubmit = (e) => {
        e.preventDefault();
        let newRecipe = {
            'id': Date.now(),
            'input': [

            ],
            'output': [
            ]
        };


        if ((formData.part1item !== '' && formData.part1quantity !== ''))
            newRecipe.input.push({ "item": formData.part1item, "quantity": formData.part1quantity });
        if ((formData.part2item !== '' && formData.part2quantity !== ''))
            newRecipe.input.push({ "item": formData.part2item, "quantity": formData.part2quantity });
        if ((formData.part3item !== '' && formData.part3quantity !== ''))
            newRecipe.input.push({ "item": formData.part3item, "quantity": formData.part3quantity });
        if ((formData.part4item !== '' && formData.part4quantity !== ''))
            newRecipe.input.push({ "item": formData.part4item, "quantity": formData.part4quantity });
        if ((formData.result1item !== '' && formData.result1quantity !== ''))
            newRecipe.output.push({ "item": formData.result1item, "quantity": formData.result1quantity });
        if ((formData.result2item !== '' && formData.result2quantity !== ''))
            newRecipe.output.push({ "item": formData.result2item, "quantity": formData.result2quantity });


        let newRecipesList = list;
        newRecipesList.push(newRecipe);
        setList(newRecipesList)

        // Step 4: Store updated form data in localStorage
        localStorage.setItem('recipesList', JSON.stringify(list));
        args.setRecipesList(newRecipesList)
        setFormData(emptyFormData);
        args.closeWindow('recipe_add');
    };

    useEffect(() => {
        if (args.recipesList)
            setList(args.recipesList);
    }, [args]);

    return (
        <div className={`${styles.RecipeAddWindow} ${stylesCommon.window_basic}`}>

            <div className={stylesCommon.window_titlebar}>
                <span>New Recipe</span>
                <span className={stylesCommon.closewindowbutton} onClick={() => args.closeWindow()}>x</span>
            </div>

            <form onSubmit={handleSubmit}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <input type="number" autoComplete="off" min='0' className={styles.recipeAddInput} name="part1quantity" value={formData.part1quantity} onChange={handleInputChange} />
                                <select className={styles.recipeAddSelect} name='part1item' value={formData.part1item} onChange={handleInputChange}>
                                    <option value=''></option>
                                    {itemDictionary.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>
                            </td>

                            <td>
                                <input type="number" autoComplete="off" min='0' className={styles.recipeAddInput} name="part2quantity" value={formData.part2quantity} onChange={handleInputChange} />
                                <select className={styles.recipeAddSelect} name='part2item' value={formData.part2item} onChange={handleInputChange}>
                                    <option value=''></option>
                                    {itemDictionary.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>

                                    ))}
                                </select>
                            </td>

                            <td>
                                <input type="number" autoComplete="off" min='0' className={styles.recipeAddInput} name="part3quantity" value={formData.part3quantity} onChange={handleInputChange} />
                                <select className={styles.recipeAddSelect} name='part3item' value={formData.part3item} onChange={handleInputChange}>
                                    <option value=''></option>
                                    {itemDictionary.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>

                                    ))}
                                </select>
                            </td>

                            <td>
                                <input type="number" autoComplete="off" min='0' className={styles.recipeAddInput} name="part4quantity" value={formData.part4quantity} onChange={handleInputChange} />
                                <select className={styles.recipeAddSelect} name='part4item' value={formData.part4item} onChange={handleInputChange}>
                                    <option value=''></option>
                                    {itemDictionary.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>

                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={4}><span className={styles.recipeArrow}>▼</span></td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <input type="number" autoComplete="off" min='0' className={styles.recipeAddInput} name="result1quantity" value={formData.result1quantity} onChange={handleInputChange} />
                                <select className={styles.recipeAddSelect} name='result1item' value={formData.result1item} onChange={handleInputChange}>
                                    <option value=''></option>
                                    {itemDictionary.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>
                            </td>

                            <td colSpan={2}>
                                <input type="number" autoComplete="off" min='0' className={styles.recipeAddInput} name="result2quantity" value={formData.result2quantity} onChange={handleInputChange} />
                                <select className={styles.recipeAddSelect} name='result2item' value={formData.result2item} onChange={handleInputChange}>
                                    <option value=''></option>
                                    {itemDictionary.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button className={`${styles.submitRecipe} ${args.settings.animations === true ? styles.submitRecipeAnimated : ''}`} type='submit'>Add</button>
            </form>
        </div>



    );
}

export default RecipeAddWindow;

import styles from './CodexWindowHelpers.module.css';
import React from 'react';
import { buildingsDictionary, itemDictionary } from '../data';
import { getIcon } from '../helpers';

const getWikiAdress = (item) => {
    const base = 'https://satisfactory.wiki.gg/wiki/';
    return base + item.replaceAll(' ', '_');
}
export const codexData = [
    { id: 'conveyor', name: 'Conveyor Belts' },
    { id: 'pipeline', name: 'Pipelines' },
    { id: 'power', name: 'Power' },
    { id: 'buildings', name: 'Buildings' },
    { id: 'items', name: 'Items' },
    { id: 'weapons', name: 'Weapons' },
];

export const getEntryData = (entryId) => {
    switch (entryId) {
        case 'conveyor':
            return (
                <div>
                    <table className={styles.codexTable}>
                        <thead>
                            <tr>
                                <td>Visual</td>
                                <td>Type</td>
                                <td>Item Limit</td>
                                <td>Difference</td>
                                <td>Unlocked from</td>
                                <td>Cost</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/codex/conveyor1.webp`} alt='Mk1' /></td>
                                <td>Conveyor Belt Mk.1</td>
                                <td>60</td>
                                <td>/</td>
                                <td><b>Tier 0</b> - HUB Upgrade 4</td>
                                <td><img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Iron_Plate.webp`} alt='Iron Plate' /></td>
                            </tr>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/codex/conveyor2.webp`} alt='Mk2' /></td>
                                <td>Conveyor Belt Mk.2</td>
                                <td>120</td>
                                <td>x2 (+60)</td>
                                <td><b>Tier 2</b> - Logistics Mk.2</td>
                                <td><img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Reinforced_Iron_Plate.webp`} alt='Reinforced Iron Plate' /></td>
                            </tr>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/codex/conveyor3.webp`} alt='Mk3' /></td>
                                <td>Conveyor Belt Mk.3</td>
                                <td>270</td>
                                <td>x2.25 (+150)</td>
                                <td><b>Tier 4</b> - Logistics Mk.3</td>
                                <td><img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Steel_Beam.webp`} alt='Steel Beam' /></td>
                            </tr>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/codex/conveyor4.webp`} alt='Mk4' /></td>
                                <td>Conveyor Belt Mk.4</td>
                                <td>480</td>
                                <td>x1.7777 (+210)</td>
                                <td><b>Tier 5</b> - Logistics Mk.4</td>
                                <td><img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Encased_Industrial_Beam.webp`} alt='Encased Industrial Beam' /></td>
                            </tr>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/codex/conveyor5.webp`} alt='Mk5' /></td>
                                <td>Conveyor Belt Mk.5</td>
                                <td>780</td>
                                <td>x1.625 (+300)</td>
                                <td><b>Tier 7</b> - Logistics Mk.5</td>
                                <td><img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Alclad_Aluminum_Sheet.webp`} alt='Alclad Aluminum Sheet' /></td>
                            </tr>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/codex/conveyor6.webp`} alt='Mk6' /></td>
                                <td>Conveyor Belt Mk.6</td>
                                <td>1200</td>
                                <td>x1.54 (+420)</td>
                                <td><b>Tier 9</b> - Peak Efficiency</td>
                                <td><img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Ficsite_Trigon.webp`} alt='Ficsite Trigon' />
                                    <img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Time_Crystal.webp`} alt='Time Crystal' /></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            );
        case 'pipeline':
            return (
                <div>
                    <table className={styles.codexTable}>
                        <thead>
                            <tr>

                                <td>Visual</td>
                                <td>Type</td>
                                <td>Max Flow Rate</td>
                                <td>Difference</td>
                                <td>Unlocked from</td>
                                <td>Cost</td>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/codex/pipeline1.webp`} alt='Mk1' /></td>
                                <td>Pipeline Mk.1</td>
                                <td>300</td>
                                <td>/</td>
                                <td><b>Tier 3</b> - Coal Power</td>
                                <td><img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Copper_Sheet.webp`} alt='Copper Sheet' /></td>
                            </tr>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/codex/pipeline2.webp`} alt='Mk2' /></td>
                                <td>Pipeline Mk.2</td>
                                <td>600</td>
                                <td>x2 (+300)</td>
                                <td><b>Tier 6</b> - Pipeline Engineering Mk.2</td>
                                <td>2<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Copper_Sheet.webp`} alt='Copper Sheet' />+
                                    1<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Plastic.webp`} alt='Plastic' /></td>
                            </tr>
                        </tbody>
                    </table>
                    <table className={styles.codexTable}>
                        <thead>
                            <tr>

                                <td>Visual</td>
                                <td>Type</td>
                                <td>Headlift</td>
                                <td>Power Usage</td>
                                <td>Unlocked from</td>
                                <td>Cost</td>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/codex/pipelinepump1.webp`} alt='Mk1' /></td>
                                <td>Pipeline Pump Mk.1</td>
                                <td>20</td>
                                <td>4MW</td>
                                <td><b>Tier 3</b> - Coal Power</td>
                                <td>2<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Copper_Sheet.webp`} alt='Copper Sheet' />
                                    + 2<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Rotor.webp`} alt='Rotor' /></td>
                            </tr>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/codex/pipelinepump2.webp`} alt='Mk2' /></td>
                                <td>Pipeline Pump Mk.2</td>
                                <td>50</td>
                                <td>8MW</td>
                                <td><b>Tier 6</b> - Pipeline Engineering Mk.2</td>
                                <td>1<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Heavy_Modular_Frame.webp`} alt='Heavy Modular Frame' />+
                                    2<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Motor.webp`} alt='Motor' />+
                                    10<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Plastic.webp`} alt='Plastic' /></td>
                            </tr>
                        </tbody>
                    </table>
                    <p className={styles.codexP}>
                        Mk.1 Pipeline Pumps apply a recommended head lift of 20 meters, but actually provide a head lift of 22 meters (as indicated by the orange area in the UI). The flow rate will quickly drop after these 22 meters, making it inadvisable to space the Pumps out in 22-meter intervals.<br />
                        Mk.2 Pipeline Pumps apply a recommended head lift of 50 meters and an actual head lift of 55 meters.<br />
                        <br />
                        The head lift is measured somewhere near the center point of the pipe inlet (2/2.5 meters towards the rear, from the center point of the Pump).<br />

                        If the Pipeline Pump is built horizontally, its head lift is measured at 2 meters above the Foundation level it is built on.<br />
                        If the Pipeline Pump is built vertically and upwards, its head lift is measured at 2/2.5 meters below its center point.</p>
                </div>
            );
        case 'power':
            return (
                <div>
                    <table className={styles.codexTable}>
                        <thead>
                            <tr>
                                <td>Visual</td>
                                <td>Name</td>
                                <td>Output</td>
                                <td>Operating Cost</td>
                                <td>Unlocked from</td>
                                <td>Wiki</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/buildings/300px/biomassburner.webp`} alt='Biomass Burner' /></td>
                                <td>Biomass Burner</td>
                                <td>30MW</td>
                                <td><img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Biomass.webp`} alt='Biomass' /></td>
                                <td><b>Tier 0</b> - HUB Upgrade 6</td>
                                <td><a href={'https://satisfactory.wiki.gg/wiki/Biomass_Burner'} target="_blank" rel="noopener noreferrer">Go</a></td>
                            </tr>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/buildings/300px/coalgenerator.webp`} alt='Coal Generator' /></td>
                                <td>Coal Generator</td>
                                <td>75MW</td>
                                <td><div className={styles.codexItemList}>
                                    45 m&sup3;/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Water.webp`} alt='Water' /><br />
                                    15/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Coal.webp`} alt='Coal' /><br />
                                    7.14/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Compacted_Coal.webp`} alt='Compacted Coal' /><br />
                                    25/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Petroleum_Coke.webp`} alt='Petroleum Coke' /><br />

                                </div>
                                </td>
                                <td><b>Tier 3</b> - Coal Power</td>
                                <td><a href={'https://satisfactory.wiki.gg/wiki/Coal-Powered_Generator'} target="_blank" rel="noopener noreferrer">Go</a></td>
                            </tr>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/buildings/300px/fuelgenerator.webp`} alt='Fuel Generator' /></td>
                                <td>Fuel Generator</td>
                                <td>250MW</td>
                                <td><div className={styles.codexItemList}>
                                    20 m&sup3;/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Fuel.webp`} alt='Fuel' /><br />
                                    20 m&sup3;/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Liquid_Biofuel.webp`} alt='Biofuel' /><br />
                                    7.5 m&sup3;/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Turbofuel.webp`} alt='Turbofuel' /><br />
                                    4.166 m&sup3;/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Rocket_Fuel.webp`} alt='Rocket Fuel' /><br />
                                    3 m&sup3;/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Ionized_Fuel.webp`} alt='Ionized Fuel' />
                                </div>
                                </td>

                                <td><b>Tier 5</b> - Petroleum Power</td>
                                <td><a href={'https://satisfactory.wiki.gg/wiki/Fuel-Powered_Generator'} target="_blank" rel="noopener noreferrer">Go</a></td>
                            </tr>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/buildings/300px/geothermalgenerator.webp`} alt='Geothermal Generator' /></td>
                                <td>Geothermal Generator</td>
                                <td>
                                    <b>Impure:</b> 50 - 150 MW<br />
                                    <b>Normal:</b> 100 -300 MW<br />
                                    <b>Pure:</b> 200 - 600 MW<br />
                                </td>
                                <td>
                                    /
                                </td>
                                <td><b>MAM: Caterium</b> - Geothermal Generator</td>
                                <td><a href={'https://satisfactory.wiki.gg/wiki/Geothermal_Generator'} target="_blank" rel="noopener noreferrer">Go</a></td>
                            </tr>

                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/buildings/300px/nuclearpowerplant.webp`} alt='Nuclear Power Plant' /></td>
                                <td>Nuclear Power Plant</td>
                                <td>2500MW</td>
                                <td><div className={styles.codexItemList}>
                                    240 m&sup3;/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Water.webp`} alt='Water' /><br />
                                    0.2/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Uranium_Fuel_Rod.webp`} alt='Uranium Fuel Rod' /><br />
                                    =&gt;<b>Waste</b>10/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Uranium_Waste.webp`} alt='Uranium Waste' /><br />
                                    0.1/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Plutonium_Fuel_Rod.webp`} alt='Plutonium Fuel Rod' /><br />
                                    =&gt;<b>Waste</b>1/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Plutonium_Waste.webp`} alt='Plutonium Waste' /><br />

                                    1/min<img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Ficsonium_Fuel_Rod.webp`} alt='Ficsonium Fuel Rod' /><br />
                                    =&gt;<b>Waste</b> None
                                </div>
                                </td>
                                <td><b>Tier 8</b> - Nuclear Power</td>
                                <td><a href={'https://satisfactory.wiki.gg/wiki/Nuclear_Power_Plant'} target="_blank" rel="noopener noreferrer">Go</a></td>
                            </tr>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/buildings/300px/alienpoweraugmenter.webp`} alt='Alien Power Augmenter' /></td>
                                <td>Alien Power Augmenter</td>
                                <td>500MW + 10% Capacity (or 30%)</td>
                                <td>
                                    5/min <img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Alien_Power_Matrix.webp`} alt='Alien Power Matrix' />
                                </td>
                                <td><b>MAM: Alien Technology</b> - Power Augmenter</td>
                                <td><a href={'https://satisfactory.wiki.gg/wiki/Alien_Power_Augmenter'} target="_blank" rel="noopener noreferrer">Go</a></td>
                            </tr>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/buildings/300px/powerstorage.webp`} alt='Power Storage' /></td>
                                <td>Power Storage</td>
                                <td>100MWh</td>
                                <td>
                                    /    </td>
                                <td><b>Tier 4</b> - Expanded Power Infrastructure</td>
                                <td><a href={'https://satisfactory.wiki.gg/wiki/Power_Storage'} target="_blank" rel="noopener noreferrer">Go</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <p className={styles.codexP}>
                        All generators can be overclocked, excepted the Alien Power Augmenter. Somersloops cannot be used.</p>
                </div>
            )
        case 'buildings':
            return (<div>
                <table className={styles.codexTable}>
                    <thead>
                        <tr>
                            <td>Visual</td>
                            <td>Name</td>
                            <td>Power Consumption</td>
                            <td>Max. Sommersloops</td>
                            <td>Slot(s) In</td>
                            <td>Slot(s) Out</td>
                            <td>Wiki</td>
                        </tr>
                    </thead>
                    <tbody>
                        {buildingsDictionary.filter(building => building.power > 0).map((building, index) => (
                            <tr key={index}>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/buildings/300px/${building.id}.webp`} alt={building.name} /></td>
                                <td>{building.name}</td>
                                <td>{building.power}MW</td>
                                <td>{building.max_somersloop}</td>
                                <td>{building.slots_in}</td>
                                <td>{building.slots_out}</td>
                                <td><a href={getWikiAdress(building.name)} target="_blank" rel="noopener noreferrer">Go</a></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>);
        case 'items':
            return (
                <p className={styles.codexP}>
                    {itemDictionary.map((item, index) => (
                        <span className={styles.itemListItem} key={index}>
                            <img className={styles.codexItemIcon} src={getIcon(item)} alt={item} />
                            {item} &nbsp; • &nbsp;
                            <a
                                href={getWikiAdress(item)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Wiki
                            </a>
                        </span>
                    ))}

                </p>);

        case 'weapons':
            return (
                <div>
                    <table className={styles.codexTable}>
                        <thead>
                            <tr>
                                <td>Weapon</td>
                                <td>Visual</td>
                                <td>Type</td>
                                <td>Damage</td>
                                <td>Fire rate</td>
                                <td>DPS</td>
                                <td>Magazine Size</td>
                                <td>Cost</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan={3}><img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/codex/rifle.webp`} alt='Rifle' /></td>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/codex/rifle_ammo_normal.webp`} alt='Ammo' /></td>
                                <td>Rifle Ammo</td>
                                <td>5</td>
                                <td>5/s</td>
                                <td>25/s</td>
                                <td>15</td>
                                <td><img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Copper_Sheet.webp`} alt='Copper Sheet' />
                                    <img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Smokeless_Powder.webp`} alt='Smokeless Powder' /></td>
                            </tr>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/codex/rifle_ammo_homing.webp`} alt='Homing Ammo' /></td>
                                <td>Homing Rifle Ammo</td>
                                <td>3</td>
                                <td>7.5/s</td>
                                <td>22.5/s</td>
                                <td>20</td>
                                <td><img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Rifle_Ammo.webp`} alt='Rifle Ammo' />
                                    <img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-High-Speed_Connector.webp`} alt='High-Speed_Connector' /></td>
                            </tr>
                            <tr>
                                <td> <img className={styles.codexTableImage} src={`${process.env.PUBLIC_URL}/img/codex/rifle_ammo_turbo.webp`} alt='Turbo Ammo' /></td>
                                <td>Turbo Rifle Ammo</td>
                                <td>4</td>
                                <td>2.5 to 15/s</td>
                                <td>10 to 60/s</td>
                                <td>75</td>
                                <td><img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Rifle_Ammo.webp`} alt='Rifle Ammo' />
                                    <img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Aluminum_Casing.webp`} alt='Aluminum Casing' />
                                    <img className={styles.codexItemIcon} src={`${process.env.PUBLIC_URL}/img/items/20px-Turbofuel.webp`} alt='Turbofuel' /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        default:
            return (<div>Entry not found</div>);
    }
};

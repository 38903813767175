
import React, { useState, useEffect } from 'react';
import styles from './RecipesWindow.module.css';
import stylesCommon from './CommonWindow.module.css';

import { getIcon } from '../helpers';

function RecipesWindow(args) {

    const deleteRecipeById = (indexToDelete) => {
        let newArray = [];

        list.forEach((recipe, index) => {
            if (index !== indexToDelete)
                newArray.push(recipe);
        });
        // setList(newArray);
        args.setRecipesList(newArray);
        localStorage.setItem('recipesList', JSON.stringify(newArray));

    }
    const [list, setList] = useState([]);

    useEffect(() => {
        if (args.recipesList)
            setList(args.recipesList);
    }, [args]);

    return (
        <div className={`${styles.RecipesWindow} ${stylesCommon.window_basic}`}>
            <div className={stylesCommon.window_titlebar}>
                <span className={stylesCommon.titlebarbutton} onClick={() => args.setCurrentFocus('recipes_add')}>New</span>

                <span>Recipes</span>
                <span className={stylesCommon.closewindowbutton} onClick={() => args.setCurrentFocus('home')}>x</span>
            </div>
            <table>
                <thead>
                    <tr>
                        <td>#</td>

                        <td colSpan={4}>Input</td>
                        <td>⇒</td>
                        <td colSpan={2}>Output</td>
                        <td></td>

                    </tr>
                </thead>
                <tbody>

                    {list.map((recipe, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>

                            {recipe.input.map((item, itemIndex) => (
                                <React.Fragment key={itemIndex}>
                                    {args.settings.useIcons === 'text' && (
                                        <td colSpan={Math.ceil(4 / recipe.input.length)}>
                                            {item.quantity} {item.item}
                                        </td>
                                    )}
                                    {args.settings.useIcons === 'icon' && (
                                        <td colSpan={Math.ceil(4 / recipe.input.length)}>
                                            {item.quantity} <img src={getIcon(item.item)} alt={item.item} />
                                        </td>
                                    )}
                                    {args.settings.useIcons === 'both' && (
                                        <td colSpan={Math.ceil(4 / recipe.input.length)}>
                                            {item.quantity}  {item.item}<img src={getIcon(item.item)} alt={item.item} />
                                        </td>
                                    )}
                                </React.Fragment>
                            ))}

                            {recipe.input.length === 0 && (
                                <td colSpan={4}></td>
                            )}

                            <td>⇒</td>

                            {recipe.output.map((item, itemIndex) => (
                                <React.Fragment key={itemIndex}>
                                    {args.settings.useIcons === 'text' && (
                                        <td colSpan={Math.ceil(2 / recipe.output.length)}>
                                            {item.quantity} {item.item}
                                        </td>
                                    )}
                                    {args.settings.useIcons === 'icon' && (
                                        <td colSpan={Math.ceil(2 / recipe.output.length)}>
                                            {item.quantity} <img src={getIcon(item.item)} alt={item.item} />
                                        </td>
                                    )}
                                    {args.settings.useIcons === 'both' && (
                                        <td colSpan={Math.ceil(2 / recipe.output.length)}>
                                            {item.quantity}  {item.item}<img src={getIcon(item.item)} alt={item.item} />
                                        </td>
                                    )}
                                </React.Fragment>
                            ))}
                            <td><span onClick={() => (deleteRecipeById(index))} className={styles.delete_recipe_button}>⮾</span></td>


                        </tr>
                    ))}




                </tbody>
            </table>
        </div>
    );
}

export default RecipesWindow;

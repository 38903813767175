import React, { useState, useRef, useEffect } from 'react';
import styles from './Select.module.css';

const Select = ({ name, options, onChange, placeholder = "Select an option", baseValue }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const dropdownRef = useRef(null);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    useEffect(() => {
        const currentRecipe = options.find(option => option.id === baseValue);
        setSelectedOption(currentRecipe);
    }, [options, baseValue]);
    // Toggle dropdown
    const handleToggle = () => setIsOpen(!isOpen);

    // Select option
    const handleSelect = (option, value) => {
        const bundledOption = {
            target: {
                name,
                value,
            }
        }
        setSelectedOption(option);
        setIsOpen(false);
        if (onChange) onChange(bundledOption);
    };


    return (
        <div className={styles.selectContainer} ref={dropdownRef}>
            <div className={styles.selectDisplay} onClick={handleToggle}>
                {selectedOption ? selectedOption.output
                    .map((item) => ` ${item.quantity} ${item.item} `)
                    .join('') : placeholder}
                <span className={styles.selectArrow}>&#9662;</span>
                <span className={styles.selectLabel}>Recipe</span>
            </div>
            {isOpen && (
                <ul className={styles.selectOptions}>
                    {options.map((option, index) => {
                        let name = '?';
                        if (option.output.length > 0) {
                            name = option.output.map((item) => ` ${item.quantity} ${item.item} `).join('');
                        } else {
                            if (option.input.length > 0) {
                                name = option.input.map((item) => ` ${item.quantity} ${item.item} `).join('');
                            }
                        }
                        return (
                            <li
                                key={index} value={option.id}
                                className={styles.selectOption}
                                onClick={() => handleSelect(option, option.id)}
                            >
                                <span className={styles.selectOptionCounter}>{index + 1}</span>
                                {name}
                            </li>
                        )
                    }
                    )}
                </ul>
            )}
        </div>
    );
};

export default Select;

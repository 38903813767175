export const appVersion = '0.9.2';
export const itemDictionary = [
    'Water',
    'Crude Oil',
    'Heavy Oil Residue',
    'Fuel',
    'Turbofuel',
    'Liquid Biofuel',
    'Alumina Solution',
    'Sulfuric Acid',
    'Nitric Acid',
    'Dissolved Silica',
    'Nitrogen Gas',
    'Rocket Fuel',
    'Ionized Fuel',
    'Excited Photonic Matter',
    'Dark Matter Residue',
    'Adaptive Control Unit',
    'AI Expansion Server',
    'AI Limiter',
    'Alclad Aluminum Sheet',
    'Alien DNA Capsule',
    'Alien Power Matrix',
    'Alien Protein',
    'Alien Remains',
    'Aluminum Casing',
    'Aluminum Ingot',
    'Aluminum Scrap',
    'Assembly Director System',
    'Automated Wiring',
    'Bacon Agaric',
    'Ballistic Warp Drive',
    'Battery',
    'Bauxite',
    'Beryl Nut',
    'Biochemical Sculptor',
    'Biomass',
    'Black Powder',
    'Blade Runners',
    'Boom Box',
    'Cable',
    'Caterium Ingot',
    'Caterium Ore',
    'Chainsaw',
    'Circuit Board',
    'Coal',
    'Compacted Coal',
    'Computer',
    'Concrete',
    'Cooling System',
    'Copper Ingot',
    'Copper Ore',
    'Copper Powder',
    'Copper Sheet',
    'Crude Oil',
    'Crystal Oscillator',
    'Cup',
    'Dark Matter Crystal',
    'Diamonds',
    'Electromagnetic Control Rod',
    'Empty Canister',
    'Empty Fluid Tank',
    'Encased Industrial Beam',
    'Encased Plutonium Cell',
    'Encased Uranium Cell',
    'Fabric',
    'FICSIT Coupon',
    'Ficsite Ingot',
    'Ficsite Trigon',
    'Ficsonium',
    'Ficsonium Fuel Rod',
    'Fused Modular Frame',
    'Gas Mask',
    'Hard Drive',
    'Hazmat Suit',
    'Heat Sink',
    'Heavy Modular Frame',
    'High-Speed Connector',
    'Hoverpack',
    'Iron Ingot',
    'Iron Ore',
    'Iron Plate',
    'Iron Rod',
    'Jetpack',
    'Leaves',
    'Limestone',
    'Magnetic Field Generator',
    'Medicinal Inhaler',
    'Mercer Sphere',
    'Modular Engine',
    'Modular Frame',
    'Motor',
    'Mycelia',
    'Neural-Quantum Processor',
    'Nobelisk Detonator',
    'Non-Fissile Uranium',
    'Nuclear Pasta',
    'Object Scanner',
    'Packaged Alumina Solution',
    'Packaged Fuel',
    'Packaged Heavy Oil Residue',
    'Packaged Ionized Fuel',
    'Packaged Liquid Biofuel',
    'Packaged Nitric Acid',
    'Packaged Nitrogen Gas',
    'Packaged Oil',
    'Packaged Rocket Fuel',
    'Packaged Sulfuric Acid',
    'Packaged Turbofuel',
    'Packaged Water',
    'Paleberry',
    'Parachute',
    'Petroleum Coke',
    'Plastic',
    'Plutonium Fuel Rod',
    'Plutonium Pellet',
    'Plutonium Waste',
    'Polymer Resin',
    'Power Shard',
    'Blue Power Slug',
    'Yellow Power Slug',
    'Purple Power Slug',
    'Pressure Conversion Cube',
    'Quartz Crystal',
    'Quickwire',
    'Radio Control Unit',
    'Raw Quartz',
    'Reanimated SAM',
    'Rebar Gun',
    'Reinforced Iron Plate',
    'Rifle',
    'Rotor',
    'Rubber',
    'SAM',
    'SAM Fluctuator',
    'Screw',
    'Silica',
    'Singularity Cell',
    'Smart Plating',
    'Smokeless Powder',
    'Solid Biofuel',
    'Stator',
    'Steel Beam',
    'Steel Ingot',
    'Steel Pipe',
    'Sulfur',
    'Supercomputer',
    'Superposition Oscillator',
    'Thermal Propulsion Rocket',
    'Time Crystal',
    'Turbo Motor',
    'Uranium',
    'Uranium Fuel Rod',
    'Uranium Waste',
    'Versatile Framework',
    'Wire',
    'Wood',
    'Xeno-Basher',
    'Xeno-Zapper',
    'Zipline'];

export const buildingsDictionary = [
    { 'id': 'minermk1', 'name': 'Miner Mk.1', 'power': 5, 'max_somersloop': 0, 'slots_in': 0, 'slots_out': 1 },
    { 'id': 'minermk2', 'name': 'Miner Mk.2', 'power': 15, 'max_somersloop': 0, 'slots_in': 0, 'slots_out': 1 },
    { 'id': 'minermk3', 'name': 'Miner Mk.3', 'power': 45, 'max_somersloop': 0, 'slots_in': 0, 'slots_out': 1 },
    { 'id': 'waterextractor', 'name': 'Water Extractor', 'power': 20, 'max_somersloop': 0, 'slots_in': 0, 'slots_out': 1 },
    { 'id': 'oilextractor', 'name': 'Oil Extractor', 'power': 40, 'max_somersloop': 0, 'slots_in': 0, 'slots_out': 1 },
    { 'id': 'resourcewellpressurizer', 'name': 'Resource Well Pressurizer', 'power': 150, 'max_somersloop': 0, 'slots_in': 0, 'slots_out': 1 },
    { 'id': 'smelter', 'name': 'Smelter', 'power': 4, 'max_somersloop': 1, 'slots_in': 1, 'slots_out': 1 },
    { 'id': 'foundry', 'name': 'Foundry', 'power': 16, 'max_somersloop': 2, 'slots_in': 2, 'slots_out': 1 },
    { 'id': 'constructor', 'name': 'Constructor', 'power': 4, 'max_somersloop': 1, 'slots_in': 1, 'slots_out': 1 },
    { 'id': 'assembler', 'name': 'Assembler', 'power': 15, 'max_somersloop': 2, 'slots_in': 2, 'slots_out': 1 },
    { 'id': 'manufacturer', 'name': 'Manufacturer', 'power': 55, 'max_somersloop': 4, 'slots_in': 4, 'slots_out': 1 },
    { 'id': 'packager', 'name': 'Packager', 'power': 10, 'max_somersloop': 0, 'slots_in': 2, 'slots_out': 2 },
    { 'id': 'refinery', 'name': 'Refinery', 'power': 30, 'max_somersloop': 2, 'slots_in': 2, 'slots_out': 2 },
    { 'id': 'blender', 'name': 'Blender', 'power': 75, 'max_somersloop': 4, 'slots_in': 4, 'slots_out': 2 },
    { 'id': 'particleaccelerator', 'name': 'Particle Accelerator', 'power': 1000, 'max_somersloop': 4, 'slots_in': 3, 'slots_out': 1 },
    { 'id': 'quantumencoder', 'name': 'Quantum Encoder', 'power': 1000, 'max_somersloop': 4, 'slots_in': 4, 'slots_out': 2 },
    { 'id': 'converter', 'name': 'Converter', 'power': 250, 'max_somersloop': 2, 'slots_in': 2, 'slots_out': 2 },
    { 'id': 'awesomesink', 'name': 'AWESOME Sink', 'power': 30, 'max_somersloop': 0, 'slots_in': 1, 'slots_out': 0 },
    { 'id': 'biomassburner', 'name': 'Biomass Burner', 'power': -30, 'max_somersloop': 0, 'slots_in': 1, 'slots_out': 0 },
    { 'id': 'coalgenerator', 'name': 'Coal Generator', 'power': -75, 'max_somersloop': 0, 'slots_in': 1, 'slots_out': 0 },
    { 'id': 'fuelgenerator', 'name': 'Fuel Generator', 'power': -250, 'max_somersloop': 0, 'slots_in': 1, 'slots_out': 0 },
    { 'id': 'nuclearpowerplant', 'name': 'Nuclear Power Plant', 'power': -2500, 'max_somersloop': 0, 'slots_in': 2, 'slots_out': 0 },
    { 'id': 'otherfactory', 'name': 'Other Factory', 'power': 0, 'max_somersloop': 0, 'slots_in': 4, 'slots_out': 4 }
];

export const testRecipes2 = [
    {
        'input': [
            { 'item': 'Iron Ingot', 'quantity': '30' },
        ],
        'output': [
            { 'item': 'Iron Plate', 'quantity': '20' },
        ]
    },
    {
        'input': [
            { 'item': 'Iron Ingot', 'quantity': '30' },
            { 'item': 'Iron Ingot', 'quantity': '30' },
            { 'item': 'Iron Ingot', 'quantity': '30' },
            { 'item': 'Iron Ingot', 'quantity': '30' },
        ],
        'output': [
            { 'item': 'Iron Plate', 'quantity': '20' },
            { 'item': 'Iron Plate', 'quantity': '20' },
        ]
    },
];

export const emptyRecipe = {
    'input': [
        { 'item': '', 'quantity': '' },
        { 'item': '', 'quantity': '' },
        { 'item': '', 'quantity': '' },
        { 'item': '', 'quantity': '' }
    ],
    'output': [
        { 'item': '', 'quantity': '' },
        { 'item': '', 'quantity': '' }
    ]
}

export const defaultSettings = {
    colorMode: 'dark',
    useIcons: 'both',
    animations: true,
    sheetPrecision: 2,
    useMinimap: true,
    target: null
}

export const correctPassword = '987654'; //temp
// FactoryVisualization.js

import React, { useEffect, useState, useCallback } from 'react';
import ReactFlow, { MiniMap, Background, addEdge, useNodesState, useEdgesState } from 'reactflow';
import 'reactflow/dist/style.css';
import EditBuildingNode from '../components/EditBuildingNode';
import { buildingsDictionary } from '../data';
import BuildingNode from '../components/BuildingNode';
import styles from './FactoryVisualization.module.css';
import buildIcon from '../img/build_gun.webp';

const nodeTypes = { buildingNode: BuildingNode };

function FactoryVisualization(data) {
    const [edges, setEdges, onEdgesChange] = useEdgesState(() => {
        const savedEdges = JSON.parse(localStorage.getItem('edges'));
        return savedEdges || [];
    });
    const [menuOpen, setMenuOpen] = useState(true);
    const [editNode, setEditNode] = useState(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(() => {
        const savedNodes = JSON.parse(localStorage.getItem('nodes'));
        return savedNodes || [];
    });

    const onConnect = useCallback(
        (params) => {
            const newEdge = {
                ...params,
                type: 'default',
                className: data.settings.animations ? styles.animatedDashEdge : '',

            };
            setEdges((eds) => addEdge(newEdge, eds));
        },
        [setEdges, data]
    );
    // Handler for deleting an edge when clicked
    const onEdgeClick = useCallback((event, edge) => {
        event.stopPropagation(); // Prevents other events from firing
        setEdges((eds) => eds.filter((e) => e.id !== edge.id)); // Remove the clicked edge
    }, [setEdges]);
    const createNode = (type, position) => {
        let buildingData = buildingsDictionary.find((building) => building.id === type.slice(0, -4));
        //adding default settings
        buildingData.settings = {
            recipe: -1,
            oc: 10000,
            somersloop: 0
        }
        const o = {
            id: `${type}-${Date.now()}`,
            type: 'buildingNode',
            position: position || { x: Math.random() * 500, y: Math.random() * 300 },
            data: buildingData,
        };
        return o;
    };
    const addNode = (type) => {
        const newNode = createNode(type);
        setNodes((nds) => [...nds, newNode]);
        setMenuOpen(false);
    };

    const deleteNode = (id) => {
        setNodes((nds) => nds.filter((node) => node.id !== id));
        setEdges((eds) => eds.filter((edge) => edge.source !== id && edge.target !== id));
        setEditNode(null);
    };

    const handleEditNode = (node) => {
        setEditNode(node);
    };
    const handleCloneNode = (node) => {
        addNode(node.data.id + 'Node');
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditNode((prevNode) => {
            const updatedNode = {
                ...prevNode,
                data: {
                    ...prevNode.data,
                    settings: { ...prevNode.data.settings, [name]: value }
                }
            };
            updateNodeArray(updatedNode); // Sync changes to nodes state
            return updatedNode;
        });
    };

    const closeEditPanel = () => {
        if (editNode) {
            updateNodeArray(editNode); // Ensure latest edits are saved
        }
        setEditNode(null);
    };

    const updateNodeArray = (newNode) => {
        let newNodes = [];
        nodes.forEach(node => {
            if (node.id !== newNode.id) {
                newNodes.push(node);
            } else {
                newNodes.push(newNode);
            }
        });
        setNodes(newNodes);
    }

    const setOc = (index) => {
        setEditNode((prevNode) => {
            const newOC = index === 0 ? 15000 : index === 1 ? 20000 : 25000;
            return {
                ...prevNode,
                data: { ...prevNode.data, settings: { ...prevNode.data.settings, oc: newOC } }
            };
        });
    };
    useEffect(() => {
        // Save nodes to localStorage whenever they change
        localStorage.setItem('nodes', JSON.stringify(nodes));
    }, [nodes]);
    useEffect(() => {
        // Save nodes to localStorage whenever they change
        localStorage.setItem('edges', JSON.stringify(edges));
    }, [edges]);
    useEffect(() => {
        setEdges((eds) =>
            eds.map((edge) => ({
                ...edge,
                className: data.settings.animations ? styles.animatedDashEdge : '',
            }))
        );
    }, [data.settings.animations, setEdges]);
    return (
        <div style={{ height: '95vh', width: '100%', position: 'relative' }}>
            <ReactFlow
                nodes={nodes.map((node) => ({
                    ...node,
                    data: {
                        ...node.data,
                        onDelete: () => deleteNode(node.id),
                        onEdit: () => handleEditNode(node), // Pass edit function as part of data
                        onClone: () => handleCloneNode(node), // Pass edit function as part of data
                        recipes: data.recipes,
                    }
                }))}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onEdgeClick={onEdgeClick}
                onConnect={onConnect}
                fitView
                nodeTypes={nodeTypes}
            >
                {data.settings.useMinimap && (
                    <MiniMap style={{ background: data.settings.colorMode === 'dark' ? '#111' : '#fff' }} maskColor="transparent" />

                )}
                <Background color={data.settings.colorMode === 'dark' ? "#aaa" : '#000'} gap={16} />
            </ReactFlow>

            <div className={styles.fabContainer}>
                <button
                    className={`${styles.fab} ${menuOpen ? styles.open : ''}`}
                    onClick={() => setMenuOpen((prev) => !prev)}
                >
                    <img src={buildIcon} className={styles.menuIcon} alt="+" />
                </button>

                {menuOpen && (
                    <div className={styles.menu}>
                        {buildingsDictionary.map((building, index) => (
                            <span onClick={() => addNode(building.id + 'Node')} className={styles.menuItem} key={index}>
                                <img src={`${process.env.PUBLIC_URL}/img/buildings/${building.id}.webp`} alt={building.name} />
                                <span className={styles.menuItemLabel}>{building.name}</span>
                            </span>
                        ))}
                    </div>
                )}
            </div>

            {editNode && (
                <EditBuildingNode
                    settings={data.settings}
                    editNode={editNode}
                    setEditNode={setEditNode}
                    recipesList={data.recipes}
                    handleInputChange={handleInputChange}
                    setOc={setOc}
                    closeEditPanel={closeEditPanel}
                />
            )}
        </div>
    );
}

export default FactoryVisualization;

export const computePartQuantity = (part, oc, somersloop, max_somersloop, precision) => {
    // Convert variables to numbers
    const partQuantity = Number(part.quantity);
    const overclock = Number(oc);
    const somersloopValue = Number(somersloop);
    const maxSomersloop = Number(max_somersloop);


    // Define the Somersloop multiplier only if max_somersloop > 0
    const somersloopMultiplier = maxSomersloop > 0
        ? 10 + (10 * somersloopValue / maxSomersloop)
        : 10; // Default to 10 if max_somersloop is 0

    const powerShardMultiplier = overclock / 100000;
    const res = Number((partQuantity * somersloopMultiplier * powerShardMultiplier));
    // console.log("Computing qty for " + part.item + ": base=" + partQuantity + " somersloops=" + somersloopValue + ", powershard=" + powerShardMultiplier + ", total=" + res)
    // Calculate the final quantity with overclock and multiplier
    return formatNumber(res, precision);
}


export function getIcon(item) {
    return `${process.env.PUBLIC_URL}/img/items/20px-${item.replaceAll(' ', '_')}.webp`;
}

export const getNumberofShards = (oc) => {
    return oc <= 10000 ? 0 : oc <= 15000 ? 1 : oc <= 20000 ? 2 : oc <= 25000 ? 3 : 3;
}

export function formatNumber(value, precision = 3) {
    const multiplier = Math.pow(10, precision);
    const roundedValue = Math.round(value * multiplier) / multiplier;
    const res = Number.isInteger(roundedValue) ? roundedValue : roundedValue.toFixed(precision);
    //console.log('rouding val=' + value + ' = ' + res);
    return Number(res);
}

import React, { useState, useEffect, useCallback } from 'react';

import styles from './EditBuildingNode.module.css';
import powerShardIcon from '../img/items/Power_Shard.webp';
import somersloopIcon from '../img/items/Somersloop.webp';
import Select from './Select';
import { computePartQuantity, getIcon, getNumberofShards } from '../helpers';

export default function EditBuildingNode({ settings, editNode, setEditNode, recipesList, handleInputChange, closeEditPanel }) {
    const [targetQuantity, setTargetQuantity] = useState('');
    const [copyPasteMessage, setCopyPasteMessage] = useState('');

    const copySettings = () => {
        const settings = editNode.data.settings;
        localStorage.setItem('settingsClipboard', JSON.stringify(settings));
        setCopyPasteMessage('Settings copied successfully !');
    };

    const pasteSettings = () => {
        const settings = JSON.parse(localStorage.getItem('settingsClipboard'));
        if (settings !== '' && settings !== null) {
            setCopyPasteMessage('Settings pasted successfully !');
            setEditNode((prevEditNode) => ({
                ...prevEditNode,
                data: { ...prevEditNode.data, settings }
            }));
        }

    };

    // Memoize computeTargetQuantity
    const computeTargetQuantity = useCallback((ocValue) => {
        const baseQuantity = recipesList.filter((recipe) => recipe.id === editNode.data.settings.recipe)[0]?.output[0]?.quantity || 1;
        return ((baseQuantity * ocValue) / 10000).toFixed(2);
    }, [editNode.data.settings.recipe, recipesList]);

    useEffect(() => {
        // Set initial targetQuantity based on initial OC value
        const initialTarget = computeTargetQuantity(editNode.data.settings.oc);
        setTargetQuantity(initialTarget);
    }, [editNode.data.settings.oc, computeTargetQuantity]);

    const handleOCChange = (newOc) => {
        setEditNode((prev) => ({
            ...prev,
            data: { ...prev.data, settings: { ...prev.data.settings, oc: newOc * 100 } }
        }));
        // Update targetQuantity based on new OC
        setTargetQuantity(computeTargetQuantity(newOc * 100));
    };

    const handleTargetQuantityChange = (e) => {
        const newTarget = e.target.value;
        setTargetQuantity(newTarget);

        // Calculate new OC based on target quantity
        const baseQuantity = recipesList.filter((recipe) => recipe.id === editNode.data.settings.recipe)[0]?.output[0]?.quantity || 1;
        const newOc = (newTarget / baseQuantity) * 10000;
        setEditNode((prev) => ({
            ...prev,
            data: { ...prev.data, settings: { ...prev.data.settings, oc: newOc } }
        }));
    };

    const setOc = (index) => {
        const newOc = index === 0 ? 15000 : index === 1 ? 20000 : 25000;
        setEditNode((prev) => ({
            ...prev,
            data: { ...prev.data, settings: { ...prev.data.settings, oc: newOc } }
        }));
        // Update targetQuantity based on new OC from power shard selection
        setTargetQuantity(computeTargetQuantity(newOc));
    };

    const currentRecipe = recipesList.find(recipe => recipe.id === editNode.data.settings.recipe);
    let errorMessage = '';
    if (currentRecipe) {
        if (currentRecipe.input.length > editNode.data.slots_in)
            errorMessage = errorMessage + '⚠️' + editNode.data.name + ' does not have enough input slots for this recipe (max: ' + editNode.data.slots_in + ').\n';
        if (currentRecipe.output.length > editNode.data.slots_out)
            errorMessage = errorMessage + '⚠️' + editNode.data.name + ' does not have enough output slots for this recipe (max: ' + editNode.data.slots_out + ').';

    }

    return (
        <div className={styles.buildingPanelBlur}>
            {errorMessage && (
                <div className={styles.buildingPanelErrorMessage}>{errorMessage}</div>
            )}
            <div className={styles.buildingPanel}>
                <span className={styles.closePanel} onClick={closeEditPanel}>X</span>
                <span className={styles.panelTitle} onClick={() => console.log(editNode)}>🔩 {editNode.data.name}</span>

                <div className={styles.buildingPanelContent}>
                    <div className={styles.buildingHeader}>
                        <Select
                            name='recipe'
                            options={recipesList}
                            onChange={handleInputChange}
                            placeholder="None"
                            baseValue={editNode.data.settings.recipe}
                        />
                    </div>

                    {editNode.data.settings.recipe > -1 && (
                        <div className={styles.building_recipe}>
                            {currentRecipe && currentRecipe.input.map((item, index) => (
                                <span key={index} className={styles.building_recipe_part}>
                                    <span className={styles.building_recipe_part_quantity}>
                                        {computePartQuantity(item, editNode.data.settings.oc, 0, 0, 2)}
                                    </span>
                                    {settings.useIcons === 'text' && (
                                        <span className={styles.building_recipe_part_item}>
                                            {item.item}
                                        </span>
                                    )}
                                    {settings.useIcons === 'icon' && (
                                        <span className={styles.building_recipe_part_item}>
                                            <img src={getIcon(item.item)} alt={item.item} />
                                        </span>
                                    )}
                                    {settings.useIcons === 'both' && (
                                        <span className={styles.building_recipe_part_item}>
                                            {item.item}
                                            <img src={getIcon(item.item)} alt={item.item} />
                                        </span>
                                    )}
                                </span>
                            ))}
                            <span className={styles.building_recipe_arrow}>►</span>
                            {currentRecipe && currentRecipe.output.map((item, index) => (
                                <span key={index} className={styles.building_recipe_result}>
                                    <span className={styles.building_recipe_result_quantity}>
                                        {computePartQuantity(item, editNode.data.settings.oc, editNode.data.settings.somersloop, editNode.data.max_somersloop, 4)}
                                    </span>

                                    {settings.useIcons === 'text' && (
                                        <span className={styles.building_recipe_result_item}>
                                            {item.item}
                                        </span>
                                    )}
                                    {settings.useIcons === 'icon' && (
                                        <span className={styles.building_recipe_result_item}>
                                            <img src={getIcon(item.item)} alt={item.item} />
                                        </span>
                                    )}
                                    {settings.useIcons === 'both' && (
                                        <span className={styles.building_recipe_result_item}>
                                            {item.item}
                                            <img src={getIcon(item.item)} alt={item.item} />
                                        </span>
                                    )}
                                </span>
                            )
                            )}
                        </div>
                    )}

                    <div className={styles.building_boosts_clock}>
                        <div className={styles.building_boosts_clock_decoration100}></div>
                        <div className={styles.building_boosts_clock_decoration150}></div>
                        <div className={styles.building_boosts_clock_decoration200}></div>
                        <div className={styles.building_boosts_clock_title}>Clock Speed:</div>

                        <input
                            type="range"
                            className={styles.ocSlider}
                            style={{
                                background: `linear-gradient(to right, #fa9549 ${editNode.data.settings.oc / 250}%, #777 ${editNode.data.settings.oc / 250}%)`,
                            }}
                            min="0"
                            max="25000"
                            name="oc"
                            onChange={(e) => handleOCChange(e.target.value / 100)}
                            value={editNode.data.settings.oc}
                        />
                        <input
                            className={styles.ocvalue}
                            type="number"
                            onChange={(e) => handleOCChange(e.target.value)}
                            value={editNode.data.settings.oc / 100}
                        />
                        <span className={styles.ocvalueafter}>%</span>
                        <div className={styles.building_boosts_clock_target_title}>Target production rate:</div>

                        <input
                            className={styles.targetInput}
                            type="number"
                            value={targetQuantity}
                            onChange={handleTargetQuantityChange}
                            placeholder="Set target quantity"
                        />
                        <span className={styles.targetInputAfter}>per min</span>


                        {Array.from({ length: 3 }, (_, index) => {
                            const ocLevel = getNumberofShards(editNode.data.settings.oc);
                            return (
                                <img
                                    key={index}
                                    src={powerShardIcon}
                                    onClick={() => setOc(index)}
                                    alt="Power Shard"
                                    data-key={index}
                                    className={index < ocLevel ? styles.powerShardIcon : styles.powerShardIconGreyed}
                                />
                            );
                        })}

                    </div>
                    <span className={styles.building_boosts_clock_label}>OVERCLOCK</span>

                    <span className={styles.building_boosts_somersloop_label}>MULTIPLIER</span>
                    <div className={styles.building_boosts_somersloop}>
                        <div className={styles.building_boosts_title}>
                            {editNode.data.max_somersloop > 0 ? (
                                <img src={somersloopIcon} alt="Somersloop" className={styles.somersloopIcon} />
                            ) : 'Unavailable for ' + editNode.data.name}
                        </div>
                        {editNode.data.max_somersloop > 0 && (
                            <span className={styles.somersloopMultiplier}>x{1 + editNode.data.settings.somersloop / editNode.data.max_somersloop}</span>
                        )}
                        {editNode.data.max_somersloop > 0 && (
                            <input
                                className={styles.somersloopInput}
                                type="number"
                                name="somersloop"
                                min={0}
                                max={editNode.data.max_somersloop}
                                value={editNode.data.settings.somersloop}
                                onChange={handleInputChange}
                            />
                        )}
                    </div>
                    <span className={styles.copySettingsButton} onClick={copySettings}>📄Copy settings</span>
                    <span className={styles.pasteSettingsButton} onClick={pasteSettings}>📋Paste Settings</span>
                </div>
            </div>
            {copyPasteMessage && (
                <div className={styles.copyPasteMessage}>{copyPasteMessage}</div>
            )}
        </div>
    );
}

import React, { useState } from 'react';
import styles from './LoginWindow.module.css';
import { correctPassword } from '../data';

function LoginWindow(args) {
    const [password, setPassword] = useState('');
    const [accessGranted, setAccessGranted] = useState(false);
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        if (newPassword === correctPassword) {

            setAccessGranted(true);
            setTimeout(function () {
                args.setHasAccess('true');
                localStorage.setItem('access', 'true');

            }, 2000);
        }
    }
    return (
        <div className={styles.LoginWindow}>
            <div className={styles.computer}>
                <div className={styles.computerDecorationLed}></div>
                <div className={styles.computerDecorationSlotOne}></div>
                <div className={styles.computerDecorationSlotTwo}></div>
                <div className={styles.computerDecorationSpeakerOne}></div>
                <div className={styles.computerDecorationSpeakerTwo}></div>
                <div className={styles.computerDecorationFicsit}>FICSIT, Inc.</div>
                <div className={styles.computerDecorationPowerButton}></div>
                <div className={`${styles.computerScreen} ${args.settings.animations === true ? styles.computerScreenAnimated : ''}`}>
                    <div className={styles.computerScreenUsernamePrompt}>Enter username : </div>
                    <div className={styles.computerScreenUsername}>FICSIT.employee.86472351902 </div>
                    <div className={styles.computerScreenPasswordPrompt}>Enter passcode : </div>
                    <input className={styles.computerScreenPassword} type='text' autoFocus value={password} onChange={handlePasswordChange} />
                    {accessGranted && (
                        <>
                            <div className={styles.computerScreenAccessGranted}>Access Granted ✔️</div>
                            <div className={styles.computerScreenAccessGranted2}>hudOs is booting...</div>
                        </>
                    )}

                </div>
            </div>
            <div className={styles.computerStand}>
            </div>
        </div>
    );
}

export default LoginWindow;

import React, { useState, useEffect, useCallback, useMemo } from 'react';

import styles from './CalculatorWindow.module.css';
import stylesCommon from './CommonWindow.module.css';

import { numberButtons, numberShortcutButtonsBasic, numberShortcutButtonsSpeed, customMultiplyShortcuts, customDivideShortcuts, divisors, basicOpButtons, handleButtonClick } from './CalculatorWindowHelpers';
function CalculatorWindow(args) {
    const [currentValue, setCurrentValue] = useState(0);
    const [history, setHistory] = useState([]);
    const [currentOp, setCurrentOp] = useState(null);
    const maxPrecision = args.settings.sheetPrecision; //base value = 3 ie. 3 decimal numbers ; unused for now
    const [digitBuffer, setDigitBuffer] = useState('');
    const [currentDisplay, setCurrentDisplay] = useState('');
    const [addPointOnNextNumber, setAddPointOnNextNumber] = useState(false);
    const [displayHelp, setDisplayHelp] = useState(false);

    const stateBundle = useMemo(() => ({
        currentValue,
        setCurrentValue,
        history,
        setHistory,
        currentOp,
        setCurrentOp,
        digitBuffer,
        setDigitBuffer,
        currentDisplay,
        setCurrentDisplay,
        addPointOnNextNumber,
        setAddPointOnNextNumber,
        maxPrecision
    }), [currentValue, history, currentOp, digitBuffer, currentDisplay, addPointOnNextNumber, maxPrecision]);

    // Memoize handleUndo
    const handleUndo = useCallback(() => {
        if (history.length === 0) {
            console.warn('No previous state to undo.');
            return;
        }
        const lastState = history.pop();
        setHistory(history);
        setCurrentValue(lastState.value);
        setCurrentDisplay(lastState.value);
        setDigitBuffer('');
        setCurrentOp(null);
    }, [history]);

    // Memoize handleResetCalculator
    const handleResetCalculator = useCallback(() => { //displays dismiss animation
        setHistory([]);
        setCurrentValue(0);
        setCurrentOp(null);
        setDigitBuffer('');
        setCurrentDisplay('');
        setAddPointOnNextNumber(false);
        if (args.settings.animations === true) {
            const el = document.getElementsByClassName(styles.historyContent);
            el[0].classList.add(styles.historyContentDismiss);
            setTimeout(function () {
                el[0].classList.remove(styles.historyContentDismiss);
            }, 2000);
        }
    }, [args.settings.animations]);

    const handleCopy = () => {
        navigator.clipboard.writeText(currentDisplay.toString());
    };

    const handlePaste = () => {
        navigator.clipboard.readText().then((text) => {
            if (!isNaN(text)) {
                handleButtonClick(text, 'number', stateBundle);
            } else {
                console.warn('Could not paste ' + text + ' as it is not a number.');
            }
        });
    };

    const calculateQuotients = (value) => {
        return divisors.map((divisor) => ({
            divisor,
            result: value / divisor,
            isRound: Number.isInteger(value / divisor),
        }));
    };

    const handleKeyboardInput = useCallback(
        (event) => {
            const key = event.key;
            if (!isNaN(key)) {
                handleButtonClick(key, 'number', stateBundle);
                return;
            }
            if (['+', '-', '*', '/'].includes(key)) {
                handleButtonClick(key, 'basicOp', stateBundle);
                return;
            }
            if (key === 'Backspace') {
                handleUndo();
                return;
            }
            if (key === 'Delete') {
                handleResetCalculator();
                return;
            }
            if (key === 'Enter') {
                handleButtonClick('=', 'equals', stateBundle);
                return;
            }
            if (key === '.') {
                handleButtonClick('.', 'point', stateBundle);
                return;
            }
            if (key === 'F1') {
                event.preventDefault();
                setDisplayHelp(true);
                return;
            }
        },
        [stateBundle, handleUndo, handleResetCalculator, setDisplayHelp]
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyboardInput);
        return () => {
            document.removeEventListener('keydown', handleKeyboardInput);
        };
    }, [handleKeyboardInput]);

    return (
        <div className={`${styles.CalculatorWindow} ${stylesCommon.window_basic}`}>
            <div className={stylesCommon.window_titlebar}>
                <span className={stylesCommon.titlebarbutton} onClick={() => setDisplayHelp(true)}>[F1] Help</span>
                <span>Calculator</span>
                <span className={stylesCommon.closewindowbutton} onClick={() => args.closeWindow()}>x</span>
            </div>

            {displayHelp && (
                <>
                    <div className={styles.helpContainerDecoration}></div>
                    <div className={styles.helpContainer} onClick={() => setDisplayHelp(false)}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>0-9</td>
                                    <td>⇒</td>
                                    <td>Numbers input</td>
                                </tr>
                                <tr>
                                    <td>+, -, *, /</td>
                                    <td>⇒</td>
                                    <td>Operations</td>
                                </tr>
                                <tr>
                                    <td>DELETE</td>
                                    <td>⇒</td>
                                    <td>AC (Reset)</td>
                                </tr>
                                <tr>
                                    <td>←</td>
                                    <td>⇒</td>
                                    <td>Undo</td>
                                </tr>
                                <tr>
                                    <td>CTRL + C</td>
                                    <td>⇒</td>
                                    <td>Copy</td>
                                </tr>
                                <tr>
                                    <td>CTRL + V</td>
                                    <td>⇒</td>
                                    <td>Paste</td>
                                </tr>
                                <tr>
                                    <td>Enter, =</td>
                                    <td>⇒</td>
                                    <td>=</td>
                                </tr>
                                <tr>
                                    <td>F1</td>
                                    <td>⇒</td>
                                    <td>Show this</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            )}
            {currentValue !== 0 && (
                <div className={styles.quotients}>
                    {calculateQuotients(currentValue).map(({ divisor, result, isRound }) => (
                        <div key={divisor} className={styles.quotientItem}>
                            <span className={styles.quotientItemBase}>{currentValue}</span>
                            <span className={styles.quotientItemDivisor}>{divisor}</span>
                            <span className={styles.quotientItemEquals}>=</span>
                            <span className={styles.quotientItemResult} style={{ color: isRound ? 'green' : 'red' }}>{result.toFixed(2)}</span>
                        </div>
                    ))}
                </div>
            )}

            <div className={styles.history}>
                <div className={styles.historyDecoration}></div>
                <div className={styles.historyContent} style={{ height: `calc(4em + ${history.length}em` }}>
                    {history.map((value, index) => (
                        <div key={index}>{value.value}</div>
                    ))}
                </div>
            </div>

            <div className={styles.calculator}>
                <div className={styles.calculatorDecorationLeft}></div>
                <div className={styles.calculatorDecorationCenter}></div>
                <div className={styles.calculatorDecorationRight}></div>
                <div className={styles.calculatorDecorationTop}>FICSIT, Inc.</div>
                <div className={`${styles.calculatorDecorationBottom} ${args.settings.animations === true
                    ? styles.calculatorDecorationBottomAnimated
                    : ''
                    }`}></div>

                <div className={styles.result}>{currentDisplay} <span className={styles.resultNextOp}>{currentOp}</span></div>

                <div className={styles.buttons}>
                    <div className={styles.correctButtons}>
                        <span className={`${styles.calculatorCorrectButton} ${styles.calculatorButton}`} onClick={handleResetCalculator}>AC</span>
                        <span className={`${styles.calculatorCorrectButton} ${styles.calculatorButton}`} onClick={() => handleUndo()}>&#x21B6;</span>
                        <span className={`${styles.calculatorCorrectButton} ${styles.calculatorButton}`} onClick={handleCopy}>C</span>
                        <span className={`${styles.calculatorCorrectButton} ${styles.calculatorButton}`} onClick={handlePaste}>P</span>
                    </div>

                    <div className={styles.customOpMultiply}>
                        {customMultiplyShortcuts.map((op) => (
                            <span className={`${styles.calculatorCustomOpButton} ${styles.calculatorButton}`} key={op} onClick={() => handleButtonClick(op, 'customMultiply', stateBundle)}>
                                {op}
                            </span>
                        ))}
                    </div>
                    <div className={styles.customOpDivide}>
                        {customDivideShortcuts.map((op) => (
                            <span className={`${styles.calculatorCustomOpButton} ${styles.calculatorButton}`} key={op} onClick={() => handleButtonClick(op, 'customDivide', stateBundle)}>
                                {op}
                            </span>
                        ))}
                    </div>

                    <div className={styles.numberShortcutButtonsBasic}>
                        {numberShortcutButtonsBasic.map((num) => (
                            <span className={styles.calculatorButton} key={num} onClick={() => handleButtonClick(num, 'number', stateBundle)}>
                                {num}
                            </span>
                        ))}
                    </div>
                    <div className={styles.numberShortcutButtonsSpeed}>
                        {numberShortcutButtonsSpeed.map((num) => (
                            <span className={styles.calculatorButton} key={num} onClick={() => handleButtonClick(num, 'number', stateBundle)}>
                                {num}
                            </span>
                        ))}
                    </div>
                    <div className={styles.opButtons}>
                        {basicOpButtons.map((op, index) => (
                            <span key={index} onClick={() => handleButtonClick(op, 'basicOp', stateBundle)} className={`${styles.calculatorOpButton} ${styles.calculatorButton}`}>{op}</span>

                        ))}
                    </div>
                    <div className={styles.numberButtons}>
                        {numberButtons.map((num) => (
                            <span className={styles.calculatorButton} key={num} onClick={() => handleButtonClick(num, 'number', stateBundle)}>
                                {num}
                            </span>
                        ))}
                        <span className={styles.calculatorButton} onClick={() => handleButtonClick('.', 'point', stateBundle)}>.</span>
                        <span className={`${styles.equalsButton} ${styles.calculatorButton}`} onClick={() => handleButtonClick(null, 'equals', stateBundle)}>=</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CalculatorWindow;




import { computePartQuantity, getNumberofShards, formatNumber } from "../helpers";
import domtoimage from 'dom-to-image-more';

export function processArray(arr) {
    const map = new Map();

    arr.forEach(item => {
        // Create a unique identifier for each object based on relevant properties
        const key = `${item.name}-${item.oc}-${item.recipe}-${item.somersloop}`;

        if (map.has(key)) {
            map.get(key).count += 1;
        } else {
            map.set(key, { ...item, count: 1 });
        }
    });

    return Array.from(map.values()).sort((a, b) => a.name.localeCompare(b.name));
}
const computeNodePower = (basePower, oc, somersloop, max_somersloop) => {
    let powerMultiplier = 1;
    if (somersloop > 0 && max_somersloop > 0)
        powerMultiplier = Math.pow((1 + somersloop / max_somersloop), 2);

    const powerOcMultiplier = Math.pow((oc / 10000), 1.321928);
    return formatNumber(basePower * powerMultiplier * powerOcMultiplier, 1);
}
const getInitialData = (args) => {
    const nodes = JSON.parse(localStorage.getItem('nodes'));
    const sheetPrecision = args.settings.sheetPrecision;
    return {
        recipes: args.recipesList,
        sheetPrecision,
        nodes
    }
}
const makeNodeSheetData = (node, recipes, sheetPrecision) => {
    const recipe = recipes.find(recipe => recipe.id === node.data.settings.recipe);
    let inComputed = [];
    let outComputed = [];

    if (node.data.settings.recipe > -1 && recipe.input) {
        recipe.input.forEach(inputItem => {
            const newItem = {
                item: inputItem.item,
                quantity: computePartQuantity(inputItem, node.data.settings.oc, 0, 0, sheetPrecision)
            }
            inComputed.push(newItem);
        });
    }
    if (node.data.settings.recipe > -1 && recipe.output) {
        recipe.output.forEach(outputItem => {
            const newItem = {
                item: outputItem.item,
                quantity: computePartQuantity(outputItem, node.data.settings.oc, node.data.settings.somersloop, node.data.max_somersloop, sheetPrecision)
            }
            outComputed.push(newItem);
        });
    }

    const { name, max_somersloop, power } = node.data;
    const { oc, somersloop } = node.data.settings;
    return {
        id: node.id,
        name,
        oc,
        somersloop,
        max_somersloop,
        powerShards: getNumberofShards(oc),
        recipe: node.data.settings.recipe,
        power: computeNodePower(power, oc, somersloop, max_somersloop),
        in: inComputed,
        out: outComputed
    }
}
const makeSheetData = (recipes, nodes, sheetPrecision) => {
    let nodesSheetData = [];
    nodes.forEach(node => {
        let nodeData = makeNodeSheetData(node, recipes, sheetPrecision);
        nodesSheetData.push(nodeData)
    });
    return nodesSheetData;
}
function getDifferenceArrays(arrayIn, arrayOut, precision) {
    const margin = Math.pow(10, -precision) || 0.01;
    const arrayMissing = [];
    const arrayProducts = [];

    // Helper function to find an item in an array
    const findItem = (arr, itemName) => arr.find(obj => obj.item === itemName);

    // Process items in arrayOut
    arrayOut.forEach(({ item, quantity: outQuantity }) => {
        const matchingItemIn = findItem(arrayIn, item);
        const inQuantity = matchingItemIn ? matchingItemIn.quantity : 0;
        const quantityDiff = outQuantity - inQuantity;

        if (quantityDiff > margin) {
            arrayMissing.push({ item, quantity: formatNumber(quantityDiff, precision) });
        } else if (quantityDiff < -margin) {
            arrayProducts.push({ item, quantity: formatNumber(-quantityDiff, precision) });
        }
    });

    // Process items in arrayIn that are not in arrayOut
    arrayIn.forEach(({ item, quantity: inQuantity }) => {
        if (!findItem(arrayOut, item)) {
            arrayProducts.push({ item, quantity: formatNumber(inQuantity, precision) });

        }
    });

    return { arrayMissing, arrayProducts };
}


const getTotals = (data, precision) => {
    let totalIn = {};
    let totalOut = {};
    let countPowerShards = 0;
    let countSomersloops = 0;
    let powerTotal = 0;
    data.forEach(node => {
        countPowerShards = countPowerShards + node.powerShards * node.count;
        countSomersloops = countSomersloops + node.somersloop * node.count;
        //  console.log('powerTotal' + powerTotal);
        powerTotal = powerTotal + computeNodePower(node.power, node.oc, node.somersloop, node.max_somersloop);
        // console.log('powerTotal=' + powerTotal);

        if (node.in) {
            node.in.forEach(item => {
                totalIn[item.item] = (totalIn[item.item] || 0) + item.quantity * node.count;
            });
        }
        if (node.out) {
            node.out.forEach(item => {
                totalOut[item.item] = (totalOut[item.item] || 0) + item.quantity * node.count;
            });
        }
    });
    const arrayIn = Object.entries(totalIn).map(([item, quantity]) => ({ item, quantity }));
    const arrayOut = Object.entries(totalOut).map(([item, quantity]) => ({ item, quantity }));


    const { arrayMissing, arrayProducts } = getDifferenceArrays(arrayIn, arrayOut, precision);
    return {
        in: arrayIn,
        out: arrayOut,
        byproducts: arrayProducts,
        missingItems: arrayMissing,
        powerShards: countPowerShards,
        somersloops: countSomersloops,
        power: powerTotal
    };
};

export const init = (args, setData) => {
    const { recipes, nodes, sheetPrecision } = getInitialData(args);
    if (nodes && recipes) {
        const sheetData = makeSheetData(recipes, nodes, sheetPrecision);
        const processedSheetData = processArray(sheetData);
        const totalsData = getTotals(processedSheetData, sheetPrecision);
        setData({
            sheetData: processedSheetData,
            totals: totalsData
        })
    } else {
        console.error("NODES OR RECIPES NOT FOUND")
    }
}


export async function takeScreenshot(divRef) {
    if (divRef) {
        divRef.current.style.overflow = 'hidden';
        console.log(divRef)

        // Ensure full content visibility
        const originalHeight = divRef.current.style.height;
        divRef.current.style.height = `${divRef.current.scrollHeight}px`;

        const dataUrl = await domtoimage.toPng(divRef.current);

        // Restore settings
        divRef.current.style.height = originalHeight;
        divRef.current.style.overflow = '';


        // Continue with saving image
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'screenshot.png';
        link.click();
    }

}


import { Handle } from 'reactflow';
import styles from './BuildingNode.module.css';
import { computePartQuantity } from '../helpers';
export default function BuildingNode({ data }) {
    const handleDeleteClick = () => {
        if (window.confirm('Are you sure you want to delete this node?')) {
            data.onDelete();
        }
    };
    const handleEditClick = (e) => {
        e.preventDefault();
        data.onEdit()
    };
    const handleCloneClick = () => {
        console.log(data);
        data.onClone()
    };

    const currentRecipe = data.recipes.find(recipe => recipe.id === data.settings.recipe);

    return (
        <div
            className={`${styles.BuildingNode} ${data.slots_in === 0
                ? styles.outOnly
                : data.slots_out === 0
                    ? styles.inOnly
                    : styles.normal
                }`}
            onDoubleClick={handleDeleteClick}
            onContextMenu={handleEditClick}
        >
            <span className={styles.BuildingNodeLabel}>{data.name}</span>
            <span className={styles.BuildingNodeLabelButton} onClick={handleCloneClick}>+</span>

            {data.slots_in > 0 && (
                <Handle type="source" position="left" style={{ background: '#c96b00' }} />
            )}
            {data.slots_out > 0 && (
                <Handle type="target" position="right" style={{ background: '#0090c9' }} />
            )}

            {data.slots_in > 0 && data.settings.recipe > -1 && (
                <div className={styles.inputSlots}>
                    {currentRecipe && currentRecipe.input.map((item, index) => (
                        <div key={index} className={styles.slotIn}><span className={styles.slotQty}>

                            {computePartQuantity(item, data.settings.oc, 0, 0, 1)}
                        </span><img src={`${process.env.PUBLIC_URL}/img/items/20px-${item.item.replaceAll(' ', '_')}.webp`} alt={item.item} />
                        </div>
                    ))}
                </div>
            )}
            {data.slots_out > 0 && data.settings.recipe > -1 && (
                <div className={styles.outputSlots}>
                    {currentRecipe && currentRecipe.output.map((item, index) => (
                        <div key={index} className={styles.slotOut}><span className={styles.slotQty}>

                            {computePartQuantity(item, data.settings.oc, data.settings.somersloop, data.max_somersloop, 1)}

                        </span><img src={`${process.env.PUBLIC_URL}/img/items/20px-${item.item.replaceAll(' ', '_')}.webp`} alt={item.item} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
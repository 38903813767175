export const exampleEdges = [
    {
        "source": "refineryNode-1731291034252",
        "sourceHandle": null,
        "target": "waterextractorNode-1731290861826",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-refineryNode-1731291034252-waterextractorNode-1731290861826"
    },
    {
        "source": "refineryNode-1731291034252",
        "sourceHandle": null,
        "target": "waterextractorNode-1731290865360",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-refineryNode-1731291034252-waterextractorNode-1731290865360"
    },
    {
        "source": "refineryNode-1731291039898",
        "sourceHandle": null,
        "target": "waterextractorNode-1731290867380",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-refineryNode-1731291039898-waterextractorNode-1731290867380"
    },
    {
        "source": "refineryNode-1731291039898",
        "sourceHandle": null,
        "target": "waterextractorNode-1731290945044",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-refineryNode-1731291039898-waterextractorNode-1731290945044"
    },
    {
        "source": "refineryNode-1731291039898",
        "sourceHandle": null,
        "target": "minermk2Node-1731290171997",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-refineryNode-1731291039898-minermk2Node-1731290171997"
    },
    {
        "source": "refineryNode-1731291034252",
        "sourceHandle": null,
        "target": "minermk2Node-1731290171997",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-refineryNode-1731291034252-minermk2Node-1731290171997"
    },
    {
        "source": "refineryNode-1731291169736",
        "sourceHandle": null,
        "target": "refineryNode-1731291039898",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-refineryNode-1731291169736-refineryNode-1731291039898"
    },
    {
        "source": "refineryNode-1731291166124",
        "sourceHandle": null,
        "target": "refineryNode-1731291034252",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-refineryNode-1731291166124-refineryNode-1731291034252"
    },
    {
        "source": "packagerNode-1731291266413",
        "sourceHandle": null,
        "target": "refineryNode-1731291034252",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-packagerNode-1731291266413-refineryNode-1731291034252"
    },
    {
        "source": "packagerNode-1731291266413",
        "sourceHandle": null,
        "target": "refineryNode-1731291039898",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-packagerNode-1731291266413-refineryNode-1731291039898"
    },
    {
        "source": "packagerNode-1731291383809",
        "sourceHandle": null,
        "target": "refineryNode-1731291039898",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-packagerNode-1731291383809-refineryNode-1731291039898"
    },
    {
        "source": "packagerNode-1731291383809",
        "sourceHandle": null,
        "target": "refineryNode-1731291034252",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-packagerNode-1731291383809-refineryNode-1731291034252"
    },
    {
        "source": "awesomesinkNode-1731291414345",
        "sourceHandle": null,
        "target": "packagerNode-1731291266413",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-awesomesinkNode-1731291414345-packagerNode-1731291266413"
    },
    {
        "source": "awesomesinkNode-1731291414345",
        "sourceHandle": null,
        "target": "packagerNode-1731291383809",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-awesomesinkNode-1731291414345-packagerNode-1731291383809"
    },
    {
        "source": "refineryNode-1731291034252",
        "sourceHandle": null,
        "target": "refineryNode-1731291166124",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-refineryNode-1731291034252-refineryNode-1731291166124"
    },
    {
        "source": "refineryNode-1731291039898",
        "sourceHandle": null,
        "target": "refineryNode-1731291169736",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-refineryNode-1731291039898-refineryNode-1731291169736"
    },
    {
        "source": "foundryNode-1731291475091",
        "sourceHandle": null,
        "target": "refineryNode-1731291166124",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-foundryNode-1731291475091-refineryNode-1731291166124"
    },
    {
        "source": "foundryNode-1731291477397",
        "sourceHandle": null,
        "target": "refineryNode-1731291169736",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-foundryNode-1731291477397-refineryNode-1731291169736"
    },
    {
        "source": "foundryNode-1731291475091",
        "sourceHandle": null,
        "target": "refineryNode-1731291034252",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-foundryNode-1731291475091-refineryNode-1731291034252"
    },
    {
        "source": "foundryNode-1731291477397",
        "sourceHandle": null,
        "target": "refineryNode-1731291039898",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-foundryNode-1731291477397-refineryNode-1731291039898"
    },
    {
        "source": "constructorNode-1731291561303",
        "sourceHandle": null,
        "target": "foundryNode-1731291475091",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-constructorNode-1731291561303-foundryNode-1731291475091"
    },
    {
        "source": "assemblerNode-1731291595907",
        "sourceHandle": null,
        "target": "foundryNode-1731291477397",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-assemblerNode-1731291595907-foundryNode-1731291477397"
    },
    {
        "source": "assemblerNode-1731291638979",
        "sourceHandle": null,
        "target": "foundryNode-1731291477397",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-assemblerNode-1731291638979-foundryNode-1731291477397"
    },
    {
        "source": "smelterNode-1731291745919",
        "sourceHandle": null,
        "target": "minermk2Node-1731291750284",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-smelterNode-1731291745919-minermk2Node-1731291750284"
    },
    {
        "source": "assemblerNode-1731291638979",
        "sourceHandle": null,
        "target": "smelterNode-1731291745919",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-assemblerNode-1731291638979-smelterNode-1731291745919"
    },
    {
        "source": "assemblerNode-1731291595907",
        "sourceHandle": null,
        "target": "smelterNode-1731291745919",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-assemblerNode-1731291595907-smelterNode-1731291745919"
    },
    {
        "source": "packagerNode-1731291383809",
        "sourceHandle": null,
        "target": "otherfactoryNode-1731476193477",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-packagerNode-1731291383809-otherfactoryNode-1731476193477"
    },
    {
        "source": "packagerNode-1731291266413",
        "sourceHandle": null,
        "target": "otherfactoryNode-1731476193477",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-packagerNode-1731291266413-otherfactoryNode-1731476193477"
    },
    {
        "source": "refineryNode-1731291169736",
        "sourceHandle": null,
        "target": "otherfactoryNode-1731476199715",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-refineryNode-1731291169736-otherfactoryNode-1731476199715"
    },
    {
        "source": "refineryNode-1731291166124",
        "sourceHandle": null,
        "target": "otherfactoryNode-1731476199715",
        "targetHandle": null,
        "type": "default",
        "className": "FactoryVisualization_animatedDashEdge__PvCNd",
        "id": "reactflow__edge-refineryNode-1731291166124-otherfactoryNode-1731476199715"
    }
];

export const exampleNodes = [
    {
        "id": "minermk2Node-1731290171997",
        "type": "buildingNode",
        "position": {
            "x": -291.051380579627,
            "y": 135.54580867520247
        },
        "data": {
            "id": "minermk2",
            "name": "Miner Mk.2",
            "max_somersloop": 0,
            "power": 15,
            "slots_in": 0,
            "slots_out": 1,
            "settings": {
                "recipe": 5,
                "oc": "25000",
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": -291.051380579627,
            "y": 135.54580867520247
        },
        "dragging": false
    },
    {
        "id": "waterextractorNode-1731290861826",
        "type": "buildingNode",
        "position": {
            "x": -120.53944792435826,
            "y": -113.15547025884376
        },
        "data": {
            "id": "waterextractor",
            "name": "Water Extractor",
            "max_somersloop": 0,
            "power": 20,
            "slots_in": 0,
            "slots_out": 1,
            "settings": {
                "recipe": 6,
                "oc": 18750,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": -120.53944792435826,
            "y": -113.15547025884376
        },
        "dragging": false
    },
    {
        "id": "waterextractorNode-1731290865360",
        "type": "buildingNode",
        "position": {
            "x": -118.09340388026547,
            "y": -16.7195847910683
        },
        "data": {
            "id": "waterextractor",
            "name": "Water Extractor",
            "max_somersloop": 0,
            "power": 20,
            "slots_in": 0,
            "slots_out": 1,
            "settings": {
                "recipe": 6,
                "oc": 18750,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": -118.09340388026547,
            "y": -16.7195847910683
        },
        "dragging": false
    },
    {
        "id": "waterextractorNode-1731290867380",
        "type": "buildingNode",
        "position": {
            "x": -110.56091897661766,
            "y": 397.35719035896847
        },
        "data": {
            "id": "waterextractor",
            "name": "Water Extractor",
            "max_somersloop": 0,
            "power": 20,
            "slots_in": 0,
            "slots_out": 1,
            "settings": {
                "recipe": 6,
                "oc": 15833.333333333332,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": -110.56091897661766,
            "y": 397.35719035896847
        },
        "dragging": false
    },
    {
        "id": "waterextractorNode-1731290945044",
        "type": "buildingNode",
        "position": {
            "x": -114.72723820081069,
            "y": 294.21593689664394
        },
        "data": {
            "id": "waterextractor",
            "name": "Water Extractor",
            "max_somersloop": 0,
            "power": 20,
            "slots_in": 0,
            "slots_out": 1,
            "settings": {
                "recipe": 6,
                "oc": 15833.333333333332,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": -114.72723820081069,
            "y": 294.21593689664394
        },
        "dragging": false
    },
    {
        "id": "refineryNode-1731291034252",
        "type": "buildingNode",
        "position": {
            "x": 132.52608654712844,
            "y": 107.6877519803135
        },
        "data": {
            "id": "refinery",
            "name": "Refinery",
            "max_somersloop": 2,
            "power": 30,
            "slots_in": 2,
            "slots_out": 2,
            "settings": {
                "recipe": 0,
                "oc": "25000",
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 132.52608654712844,
            "y": 107.6877519803135
        },
        "dragging": false
    },
    {
        "id": "refineryNode-1731291039898",
        "type": "buildingNode",
        "position": {
            "x": 133.2037810081519,
            "y": 208.80820384716313
        },
        "data": {
            "id": "refinery",
            "name": "Refinery",
            "max_somersloop": 2,
            "power": 30,
            "slots_in": 2,
            "slots_out": 2,
            "settings": {
                "recipe": 0,
                "oc": "25000",
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 133.2037810081519,
            "y": 208.80820384716313
        },
        "dragging": false
    },
    {
        "id": "refineryNode-1731291166124",
        "type": "buildingNode",
        "position": {
            "x": 383.60353413932455,
            "y": -204.44130132375656
        },
        "data": {
            "id": "refinery",
            "name": "Refinery",
            "max_somersloop": 2,
            "power": 30,
            "slots_in": 2,
            "slots_out": 2,
            "settings": {
                "recipe": 1,
                "oc": 4166.666666666667,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 383.60353413932455,
            "y": -204.44130132375656
        },
        "dragging": false
    },
    {
        "id": "refineryNode-1731291169736",
        "type": "buildingNode",
        "position": {
            "x": 375.55590911983654,
            "y": 7.789761511931729
        },
        "data": {
            "id": "refinery",
            "name": "Refinery",
            "max_somersloop": 2,
            "power": 30,
            "slots_in": 2,
            "slots_out": 2,
            "settings": {
                "recipe": 1,
                "oc": 4166.666666666667,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 375.55590911983654,
            "y": 7.789761511931729
        },
        "dragging": false
    },
    {
        "id": "packagerNode-1731291266413",
        "type": "buildingNode",
        "position": {
            "x": 431.8954995749013,
            "y": 337.37046994454903
        },
        "data": {
            "id": "packager",
            "name": "Packager",
            "max_somersloop": 0,
            "power": 10,
            "slots_in": 2,
            "slots_out": 2,
            "settings": {
                "recipe": 7,
                "oc": 16666.666666666668,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 431.8954995749013,
            "y": 337.37046994454903
        },
        "dragging": false
    },
    {
        "id": "packagerNode-1731291383809",
        "type": "buildingNode",
        "position": {
            "x": 431.6306538400602,
            "y": 437.44211761146823
        },
        "data": {
            "id": "packager",
            "name": "Packager",
            "max_somersloop": 0,
            "power": 10,
            "slots_in": 2,
            "slots_out": 2,
            "settings": {
                "recipe": 7,
                "oc": 16666.666666666668,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 431.6306538400602,
            "y": 437.44211761146823
        },
        "dragging": false
    },
    {
        "id": "awesomesinkNode-1731291414345",
        "type": "buildingNode",
        "position": {
            "x": 637.3410515316721,
            "y": 386.2674600737568
        },
        "data": {
            "id": "awesomesink",
            "name": "Awesome Sink",
            "max_somersloop": 0,
            "power": 30,
            "slots_in": 1,
            "slots_out": 0,
            "settings": {
                "recipe": -1,
                "oc": 10000,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 637.3410515316721,
            "y": 386.2674600737568
        },
        "dragging": false
    },
    {
        "id": "foundryNode-1731291475091",
        "type": "buildingNode",
        "position": {
            "x": 635.7833168662678,
            "y": -184.72301215916914
        },
        "data": {
            "id": "foundry",
            "name": "Foundry",
            "max_somersloop": 2,
            "power": 16,
            "slots_in": 2,
            "slots_out": 1,
            "settings": {
                "recipe": 2,
                "oc": 16666.666666666668,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 635.7833168662678,
            "y": -184.72301215916914
        },
        "dragging": false
    },
    {
        "id": "foundryNode-1731291477397",
        "type": "buildingNode",
        "position": {
            "x": 615.765503728006,
            "y": 103.69170190640277
        },
        "data": {
            "id": "foundry",
            "name": "Foundry",
            "max_somersloop": 2,
            "power": 16,
            "slots_in": 2,
            "slots_out": 1,
            "settings": {
                "recipe": 2,
                "oc": 16666.666666666668,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 615.765503728006,
            "y": 103.69170190640277
        },
        "dragging": false
    },
    {
        "id": "constructorNode-1731291561303",
        "type": "buildingNode",
        "position": {
            "x": 866.6589481285451,
            "y": -184.8884053704112
        },
        "data": {
            "id": "constructor",
            "name": "Constructor",
            "max_somersloop": 1,
            "power": 4,
            "slots_in": 1,
            "slots_out": 1,
            "settings": {
                "recipe": 4,
                "oc": 11111,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 866.6589481285451,
            "y": -184.8884053704112
        },
        "dragging": false
    },
    {
        "id": "assemblerNode-1731291595907",
        "type": "buildingNode",
        "position": {
            "x": 811.5385017989194,
            "y": 104.49381738213546
        },
        "data": {
            "id": "assembler",
            "name": "Assembler",
            "max_somersloop": 2,
            "power": 15,
            "slots_in": 2,
            "slots_out": 1,
            "settings": {
                "recipe": 3,
                "oc": 16666.666666666668,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 811.5385017989194,
            "y": 104.49381738213546
        },
        "dragging": false
    },
    {
        "id": "assemblerNode-1731291638979",
        "type": "buildingNode",
        "position": {
            "x": 815.0261701132814,
            "y": 216.77566986092646
        },
        "data": {
            "id": "assembler",
            "name": "Assembler",
            "max_somersloop": 2,
            "power": 15,
            "slots_in": 2,
            "slots_out": 1,
            "settings": {
                "recipe": 3,
                "oc": 16666.666666666668,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 815.0261701132814,
            "y": 216.77566986092646
        },
        "dragging": false
    },
    {
        "id": "smelterNode-1731291745919",
        "type": "buildingNode",
        "position": {
            "x": 614.5042453256334,
            "y": 217.08673480673372
        },
        "data": {
            "id": "smelter",
            "name": "Smelter",
            "max_somersloop": 1,
            "power": 4,
            "slots_in": 1,
            "slots_out": 1,
            "settings": {
                "recipe": 8,
                "oc": 10666.666666666666,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 614.5042453256334,
            "y": 217.08673480673372
        },
        "dragging": false
    },
    {
        "id": "minermk2Node-1731291750284",
        "type": "buildingNode",
        "position": {
            "x": 440.52109974577684,
            "y": 218.37156111975378
        },
        "data": {
            "id": "minermk2",
            "name": "Miner Mk.2",
            "max_somersloop": 0,
            "power": 15,
            "slots_in": 0,
            "slots_out": 1,
            "settings": {
                "recipe": 9,
                "oc": 5333.333333333333,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 440.52109974577684,
            "y": 218.37156111975378
        },
        "dragging": false
    },
    {
        "id": "otherfactoryNode-1731476193477",
        "type": "buildingNode",
        "position": {
            "x": 203.43361128315485,
            "y": 384.02582410975805
        },
        "data": {
            "id": "otherfactory",
            "name": "Other Factory",
            "power": 0,
            "max_somersloop": 0,
            "slots_in": 4,
            "slots_out": 4,
            "settings": {
                "recipe": 11,
                "oc": 10000,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 203.43361128315485,
            "y": 384.02582410975805
        },
        "dragging": false
    },
    {
        "id": "otherfactoryNode-1731476199715",
        "type": "buildingNode",
        "position": {
            "x": 137.64672780649113,
            "y": -105.56797363535343
        },
        "data": {
            "id": "otherfactory",
            "name": "Other Factory",
            "power": 0,
            "max_somersloop": 0,
            "slots_in": 4,
            "slots_out": 4,
            "settings": {
                "recipe": 10,
                "oc": 10000,
                "somersloop": 0
            }
        },
        "width": 142,
        "height": 82,
        "selected": false,
        "positionAbsolute": {
            "x": 137.64672780649113,
            "y": -105.56797363535343
        },
        "dragging": false
    }
];

export const exampleRecipesList = [
    {
        "input": [
            {
                "item": "Bauxite",
                "quantity": "120"
            },
            {
                "item": "Water",
                "quantity": "180"
            }
        ],
        "output": [
            {
                "item": "Alumina Solution",
                "quantity": "120"
            },
            {
                "item": "Silica",
                "quantity": "50"
            }
        ]
    },
    {
        "input": [
            {
                "item": "Alumina Solution",
                "quantity": "240"
            },
            {
                "item": "Coal",
                "quantity": "120"
            }
        ],
        "output": [
            {
                "item": "Aluminum Scrap",
                "quantity": "360"
            },
            {
                "item": "Water",
                "quantity": "120"
            }
        ]
    },
    {
        "input": [
            {
                "item": "Aluminum Scrap",
                "quantity": "90"
            },
            {
                "item": "Silica",
                "quantity": "75"
            }
        ],
        "output": [
            {
                "item": "Aluminum Ingot",
                "quantity": "60"
            }
        ]
    },
    {
        "input": [
            {
                "item": "Aluminum Ingot",
                "quantity": "30"
            },
            {
                "item": "Copper Ingot",
                "quantity": "10"
            }
        ],
        "output": [
            {
                "item": "Alclad Aluminum Sheet",
                "quantity": "30"
            }
        ]
    },
    {
        "input": [
            {
                "item": "Aluminum Ingot",
                "quantity": "90"
            }
        ],
        "output": [
            {
                "item": "Aluminum Casing",
                "quantity": "60"
            }
        ]
    },
    {
        "input": [

        ],
        "output": [
            {
                "item": "Bauxite",
                "quantity": "240"
            }
        ]
    },
    {
        "input": [

        ],
        "output": [
            {
                "item": "Water",
                "quantity": "120"
            }
        ]
    },
    {
        "input": [
            {
                "item": "Alumina Solution",
                "quantity": "120"
            },
            {
                "item": "Empty Canister",
                "quantity": "120"
            }
        ],
        "output": [
            {
                "item": "Packaged Alumina Solution",
                "quantity": "120"
            }
        ]
    },
    {
        "input": [
            {
                "item": "Copper Ore",
                "quantity": "30"
            }
        ],
        "output": [
            {
                "item": "Copper Ingot",
                "quantity": "30"
            }
        ]
    },
    {
        "input": [

        ],
        "output": [
            {
                "item": "Copper Ore",
                "quantity": "60"
            }
        ]
    },
    {
        "input": [

        ],
        "output": [
            {
                "item": "Coal",
                "quantity": "100"
            }
        ]
    },
    {
        "input": [

        ],
        "output": [
            {
                "item": "Empty Canister",
                "quantity": "400"
            }
        ]
    }
];
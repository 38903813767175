import React, { useEffect, useState } from 'react';
import FactoryVisualization from './FactoryVisualization';
import styles from './FactoryWindow.module.css';
import stylesCommon from './CommonWindow.module.css';

function FactoryWindow(args) {
    const [recipes, setRecipes] = useState();

    useEffect(() => {
        const recipesList = JSON.parse(localStorage.getItem('recipesList'));
        setRecipes(recipesList);
    }, []);

    // Only render FactoryVisualization once recipes are loaded
    if (!recipes) return <div>Loading...</div>;

    return (
        <div className={`${styles.FactoryWindow} ${stylesCommon.window_basic}`}>
            {<FactoryVisualization settings={args.settings} recipes={recipes} />}
        </div>
    );
}

export default FactoryWindow;
